const Menu = [
  {
    name: "首页",
    link: "/",
  },
  {
    name: "活动介绍",
    link: "/activity",
  },
  {
    name: "通知公告",
    link: "/notice",
  },
  {
    name: "最新动态",
    link: "/lastest",
  },
  {
    name: "赛区风采",
    link: "/district",
    subMenu: [],
  },
  {
    name: "选手展示",
    link: "/players",
    subMenu: [],
  },
  {
    name: "网上报名",
    link: "/application",
    subMenu: [],
  },
  {
    name: "网上投票",
    link: "/vote",
    subMenu: [],
  },
  // {
  //   name: "宣传报道",
  //   link: "/media",
  // },

  {
    name: "合作单位",
    link: "/company",
  },
  {
    name: "网上商城",
    link: "/mall",
    subMenu: [],
  },
  // {
  //   name: "首页",
  //   link: "/",
  // },
];

export default Menu;
