<!-- src/pc/PCPage.vue -->
<template>
  <div>
    <Header v-on:MenuList="handleMenuList"></Header>
    <!-- PC端页面内容 -->
    <div class="about" v-if="mallInfo">
      <div class="container2" >
        <div>
          <div>
            <div class="bread">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"
                  >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item :to="{ path: '/mall' }"
                  >网上商城</el-breadcrumb-item
                >
                <el-breadcrumb-item>购买</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
          <!-- <div class="content main" v-if="Agreement">
            <div class="active-title">报名条款</div>
            <div class="active-content">
              <div class="active-msg" v-html="RegistrationGuidelines"></div>
            </div>
            <div class="button-box">
              <el-button
                type="primary"
                @click="Agreement = false"
                style="margin-bottom: 10px"
              >
                我已阅读并同意
              </el-button>
            </div>
          </div> -->
          <div class="content main" >
            <div class="active-title" style="text-align: center">
              确认商品信息
            </div>
            <el-divider border-style="dashed" />
            <div class="active-price">
              <div class="active-price-box">商品信息</div>
            </div>
            <div>
              <div class="active-address-notop" style="margin-top: 20px">
                商品名称：{{ mallInfo.storeTitle }}
              </div>
              <el-row :gutter="24">
                <el-col :span="12">
                  <div class="active-address-notop" style="margin-top: 20px">
                    商品规格：<el-select
                      v-model="specId"
                      placeholder="选择规格"
                      style="width: 240px"
                      @change="change"
                      clearable
                    >
                      <el-option
                        v-for="item in mallInfo.arr"
                        :key="item.specId"
                        :label="item.storePicture"
                        :value="item.specId"
                      />
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="active-address-notop" style="margin-top: 20px">
                    商品数量：<el-input-number
                      v-model="num"
                      :min="1"
                      @change="handleChange"
                    />
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="12">
                  <div class="active-address-notop" style="margin-top: 20px">
                    单价：{{
                      specInfo
                        ? specInfo.actualPrice
                        : mallInfo.arr[0].actualPrice
                    }}
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="active-address-notop" style="margin-top: 20px">
                    运费：{{ mallInfo.storeYf }}
                  </div>
                </el-col>
              </el-row>
              <!-- <el-divider border-style="dashed" /> -->
              <!-- <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    margin-bottom: 10px;
                  "
                >
                  <div class="active-address-notop">
                    集合地：{{ applicationInfo.activityJhd }}
                  </div>
                  <div class="active-address-notop">
                    目的地：{{ applicationInfo.activityMdd }}
                  </div>
                </div> -->
            </div>
            <div style="margin-top: 10px"></div>
            <div class="active-price">
              <div class="active-price-box">地址信息</div>
            </div>
            <div class="report-form">
              <el-form
                ref="form"
                :model="form"
                label-position="left"
                label-width="90px"
                class="from"
                :rules="rules"
              >
                <div class="from-box">
                  <el-row :gutter="24">
                    <el-col :span="12">
                      <el-form-item label="收货人" prop="userNickname">
                        <el-input
                          v-model="form.userNickname"
                          placeholder="请输入收货人"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="手机号" prop="userPhone">
                        <el-input
                          v-model="form.userPhone"
                          placeholder="请输入手机号"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col :span="24">
                      <el-form-item label="地区" prop="code">
                        <el-cascader
                            size="large"
                            ref="refCascader"
                            :options="areaList"
                            v-model="selectedOptions"
                            placeholder="请选择地区"
                            style="width: 100%"
                            clearable
                            @change="changeArea"
                          ></el-cascader>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                      <el-form-item label="省份" prop="userProvince">
                        <el-input
                          v-model="userProvince"
                          placeholder="请输入省份"
                          style="width: 100%"
                          clearable
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="市" prop="userProvince">
                        <el-input
                          v-model="userProvince"
                          placeholder="请输入市"
                          style="width: 100%"
                          clearable
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="区" prop="userCounty">
                        <el-input
                          v-model="userCounty"
                          placeholder="请输入区"
                          style="width: 100%"
                          clearable
                        />
                      </el-form-item>
                    </el-col> -->
                  </el-row>
                  <el-form-item label="详细地址" prop="userSite">
                    <el-input
                      type="textarea"
                      v-model="form.userSite"
                      placeholder="请输入详细地址"
                    />
                  </el-form-item>
                  <!-- <v-region @values="regionChange"></v-region> -->
                </div>
              </el-form>
              <div class="button-box">
                <!-- <el-button type="primary" @click="addPersonnel">
                    添加报名人
                  </el-button> -->
                <el-button type="primary" @click="toImport">导入地址</el-button>
              </div>
            </div>
            <div style="margin-top: 10px"></div>
            <div class="active-price">
              <div class="active-price-box">订单备注</div>
            </div>
            <div class="active-other">
              <el-form-item label="订单备注" style="padding-left: 10px">
                 <el-input v-model="remark"></el-input>
              </el-form-item>
            </div>
            <div style="margin-top: 10px"></div>
            <div class="active-price">
              <div class="active-price-box">支付方式</div>
            </div>
            <div class="active-other">
              <el-form-item label="支付方式" style="padding-left: 10px">
                <el-radio-group v-model="orderPay" disabled>
                  <el-radio label="1" disabled>微信</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div style="margin-top: 10px"></div>
            <div
              class="active-price"
              style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
                overflow-x: hidden;
              "
            >
              <div class="active-price-box">结算信息</div>

              <div class="active-price-peo">
                <span>实付款</span>
                <span
                  >¥{{
                    specInfo
                      ? Number(specInfo.actualPrice) * Number(num) +
                        Number(mallInfo.storeYf)
                      : Number(mallInfo.arr[0].actualPrice) * Number(num) +
                        Number(mallInfo.storeYf)
                  }}</span
                >
              </div>
            </div>
            <div style="margin-top: 10px; height: 10px"></div>
            <div style="margin-top: 10px; height: 10px"></div>
            <div style="margin-top: 10px; height: 10px"></div>
            <el-button
              type="primary"
              style="margin-left: 10px"
              @click="baoming"
            >
              确认购买
            </el-button>

            <div style="margin-top: 10px"></div>
          </div>
        </div>
      </div>
    </div>
    <el-empty style="min-height: calc(100vh - 290px);" v-else description="暂无数据"></el-empty>
    <Footer></Footer>
    <el-dialog
      v-model="dialogVisible"
      title="购买确认"
      width="500"
      :before-close="handleClose"
    >
      <span>确认无误，请点击确认购买</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirm()"> 确认购买 </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="QecodeModel"
      title="请扫码支付"
      width="300"
      :before-close="handleClose_Qecode"
    >
      <div style="text-align: center" v-if="QecodeModel">
        <div class="qrcode" ref="qrcode" id="qrcode" style="margin: 0 auto;width: 200px;height: 200px;"></div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="isPay()">已支付</el-button>
          <el-button @click="QecodeModel = false">关闭</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="ImportModel"
      title="导入报名人"
      width="850"
      :before-close="handleClose_Import"
    >
      <el-table
        :data="ImportData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        ref="myTable"
      >
        <!-- @selection-change="handleGoodsChange" -->
        <!-- <el-table-column
          type="selection"
          width="50"
          :selectable="checkSelectable"
        /> -->
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column label="姓名" prop="userNickname"></el-table-column>
        <el-table-column label="手机号" prop="userPhone"></el-table-column>
        <el-table-column label="省" prop="userProvince"></el-table-column>
        <el-table-column label="市" prop="userCity"></el-table-column>
        <el-table-column label="区" prop="userCounty"></el-table-column>
        <el-table-column label="详细地址" prop="userSite"></el-table-column>
      </el-table>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="addImportPersonnel">确定</el-button>
          <el-button @click="ImportModel = false">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// 页面逻辑
import Header from "@/components/header.vue"; // 引入头部组件
import breadcrumbs from "@/components/Breadcrumbs.vue"; // 引入面包屑组件
import LatestActivities from "@/components/LatestActivities.vue"; // 引入最新活动组件
import Footer from "@/components/Footer.vue";
import mobileHeader from "@/components/mobileHeader.vue"; // 引入头部组件
import navs from "@/components/nav.vue"; // 引入导航组件
import { regionData, codeToText, TextToCode } from "element-china-area-data";
let img = require("@/static/img/banner.jpg"); // 引入轮播图图片
import api from "@/common/request/request";
import apiuser from "@/common/request/user";
import imgUrl from "@/common/imgUrl";
import router from "@/router";
import { toRaw } from "@vue/reactivity";
import QRCode from 'qrcodejs2'
export default {
  // 页面逻辑
  components: {
    Header, // 注册头部组件
    breadcrumbs, // 注册面包屑组件
    LatestActivities, // 注册最新活动组件
    Footer, // 注册底部组件
    mobileHeader, // 注册移动端头部组件
    navs, // 注册导航组件
  },
  data() {
    return {
      ImportModel: false, // 导入弹窗显示状态
      mallInfo: null, // 商城信息
      specId: "", // 规格id
      num: 1,
      specInfo: null, // 规格信息
      form: {
        qxUserAddresId: "",
      },
      remark:'',
      rules: {
        userNickname: [
          { required: true, message: "请输入收货人", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        userPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[34578]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],

        userSite: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
          {
            min: 5,
            max: 100,
            message: "长度在 5 到 100 个字符",
            trigger: "blur",
          },
        ],
        userProvince: [
          { required: true, message: "请选择省份", trigger: "blur" },
        ],
        userCity: [{ required: true, message: "请选择市", trigger: "blur" }],
        userCounty: [{ required: true, message: "请选择区", trigger: "blur" }],
        code: [{ required: true, message: "请选择地区", trigger: "blur" }],
      },
      options: regionData,
      selectedOptions: [],
      orderPay: "1",
      dialogVisible: false,
      QecodeModel: false, // 二维码弹窗显示状态
      ImportData: [],
      templateSelection: "",
      templateRadio: null,
      address: {},
      orderId:'',
      areaList: [],
    };
  },

  watch: {
    // $route(to, from) {
    //     if (this.$route.query.status) {
    //         this.status = this.$route.query.status
    //         this.getActivityLists() // 获取推荐活动数据
    //     }
    // }
  },
  created() {
    console.log(regionData)
    let id = this.$route.query.id;
    this.specId = Number(this.$route.query.specId);
    this.getMallInfo(id);
    this.getAreaList();
  },
  mounted() {
    // this.selectedOptions=codeToText[selectedOptions[0]], codeToText[selectedOptions[1]], codeToText[selectedOptions[2]]
    // 页面挂载
  },
  methods: {
    changeArea(e){
      this.selectedOptions = e;
      this.form.code=e
      console.log(e)
      if(e.length === 1){
      this.form.userProvince = this.areaList.find(item => item.code == e[0]).label;
      this.form.userCity = "";
      this.form.userCounty = "";
      }
      if(e.length === 2){
      this.form.userProvince = this.areaList.find(item => item.code == e[0]).label;
      this.form.userCity = this.areaList.find(item => item.code == e[0]).children.find(item2 => item2.code == e[1]).label;
      this.form.userCounty = "";
      }
      if(e.length === 3){
      this.form.userProvince = this.areaList.find(item => item.code == e[0]).label;
      this.form.userCity = this.areaList.find(item => item.code == e[0]).children.find(item2 => item2.code == e[1]).label;
      this.form.userCounty = this.areaList.find(item => item.code == e[0]).children.find(item2 => item2.code == e[1]).children.find(item3 => item3.code == e[2]).label;
      console.log(this.form.userProvince);
      console.log(this.form.userCity);
      console.log(this.form.userCounty);
      }
    },
    getAreaList() {
      api.getRegionList().then((res) => {
        this.areaList = res.data.data[0].children;
        console.log(this.areaList);
        this.areaList.forEach((item) => {
          item.value = item.code;
          if (Object.keys(item).includes('children')&&item.children !== null &&item.children!==[]) {
            item.children.forEach((item2) => {
              item2.value = item2.code;
              if (
                Object.keys(item2).includes("children") &&
                item2.children !== null &&
                item2.children !== []
              ) {
                item2.children.forEach((item3) => {
                  item3.value = item3.code;
                });
              }
            });
          }
        });
      });
    },
    isPay(){
      this.$router.push({ path: "/user/orderDetail", query: { id: this.orderId} });
    },
    handleChange(e){
      console.log(e)
      this.num=e
    },
    handleSelectionChange(val) {
      this.selectedRows = val;
      if (val.length > 1) {
        this.$refs.myTable.clearSelection();
        this.$refs.myTable.toggleRowSelection(val.pop());
      }
      if (val.length != 0) {
        this.selectProtocolId = val[val.length - 1].qxUserAddresId;
      }
      console.log(val);

      this.address = val[val.length - 1];
    },
    addImportPersonnel() {
      this.ImportModel = false;
      this.form.code = JSON.parse(this.address.code);
      this.selectedOptions = JSON.parse(this.address.code);
      this.form.userProvince = this.address.userProvince;
      this.form.userCity = this.address.userCity;
      this.form.userCounty = this.address.userCounty;
      this.form.userSite = this.address.userSite;
      this.form.userPhone = this.address.userPhone;
      this.form.userNickname = this.address.userNickname;
      this.form.qxUserAddresId = this.address.qxUserAddresId;
    },
    toImport() {
      this.ImportModel = true;
      api
        .getAddressList({
          qxAppUserId: JSON.parse(localStorage.getItem("zsjyuserInfo")).qxAppUserId,
        })
        .then((res) => {
          console.log(res);
          this.ImportData = res.data.data;
        });
    },
    confirm() {
      if (
        !localStorage.getItem("zsjyuserInfo") ||
        localStorage.getItem("zsjyuserInfo") == null ||
        localStorage.getItem("zsjyuserInfo") === "null"
      ) {
        this.$message.error("请先登录");
        return false;
      }
      this.form.code = JSON.stringify(this.form.code);
      this.form.qxAppUserId = JSON.parse(localStorage.getItem("zsjyuserInfo")).qxAppUserId;
      let arr = this.mallInfo.arr.find((item) => item.specId == this.specId);
      // console.log(arr);
      arr.storeNumber = this.num;
      // arr.storeName = this.mallInfo.storeTitle;
      arr.storeTotal =  Number(arr.storeNumber) * Number(arr.actualPrice) + Number(this.mallInfo.storeYf);
      arr.userName = JSON.parse(localStorage.getItem("zsjyuserInfo")).phone;
      arr.storeId = this.mallInfo.storeId;
      arr.orderPicture = arr.storePicture;
      arr.storeName = this.mallInfo.storeTitle;
      arr.skuPicture = arr.skuPicture;
      arr.orderWeight = Number(arr.storeNumber) * Number(arr.storeWeight);
      console.log(arr);
      // return false
      var data = {
        arr: [arr],
        zsjyAppUserAddress: this.form,
        userName: JSON.parse(localStorage.getItem("zsjyuserInfo")).phone,
        orderType: "1",
        remark:this.remark
      };
      console.log(data);
      // console.log(JSON.parse(localStorage.getItem("userInfo")));
      // return false;
      api.addorder(data).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.orderId = res.data.data;
          var data = {
            arr: [arr],
            userName: JSON.parse(localStorage.getItem("zsjyuserInfo")).phone,
            orderType:'1',
            orderId:res.data.data
          };
          this.pay(data);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    pay(data) {
      // data.orderType = "2";
      // data.yaoqingma = localStorage.getItem("qrCode");
      // return false;
      api.orderpay(data).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.creatQrCode(res.data.data.codeUrl);
          // location.href = res.data.data.codeUrl
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    creatQrCode(codeUrl) {
      this.QecodeModel = true;
      const that = this;
      setTimeout(function () {
        new QRCode(that.$refs.qrcode, {
          text: codeUrl,
          width: 200,
          height: 200,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        });
      }, 200);
    },
    baoming() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogVisible = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addressChange(arr) {
      this.form.code = arr;
      this.form.userProvince = codeToText[arr[0]];
      this.form.userCity = codeToText[arr[1]];
      if (this.form.userCity == "市辖区") {
        this.form.userCity = codeToText[arr[0]];
      }
      this.form.userCounty = codeToText[arr[2]];
      console.log(this.form);
    },
    change(e) {
      console.log(e);
      this.specId = e;
      if (this.specId) {
        this.specInfo = this.mallInfo.arr.find((item) => item.specId == e);
      } else {
        this.specInfo = "";
      }
      console.log(this.specInfo);
    },
    getMallInfo(id) {
      api.getStoreInfo(id).then((res) => {
        console.log(res);
        this.mallInfo = res.data.data;
        this.mallInfo.storeImgs = this.mallInfo.storeImgs.split(",");
        console.log(this.mallInfo);
        if (this.specId) {
        } else {
          this.specId = this.mallInfo.arr[0].specId;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  &:hover {
    color: #b52024 !important;
  }
}
.about {
  overflow-x: hidden !important;
  .el-breadcrumb {
    font-size: 16px;
  }
  // background-image: url(../assets/bg.png);
  .container2 {
    min-height: calc(100vh - 290px);
    overflow: auto;
    width: 1080px;
    margin: 0 auto;
    background-color: #fff;
    overflow-x: hidden;
  }
  .bread {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 20px;
    margin-top: 36px;
    margin-left: 10px;
    > span {
      padding-right: 10px;
    }
  }
  .infotitle {
    text-align: center;
    margin-top: 68px;
    font-size: 32px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 34px;
    margin-left: 44px;
    margin-right: 44px;
  }
  .infofeilei {
    display: flex;
    justify-content: space-around;
    margin-top: 18px;
    font-size: 16px;
    color: #858585;
  }
  .infocontent {
    margin-top: 44px;
    margin-left: 48px;
    margin-bottom: 44px;
    margin-right: 44px;
  }
}
.huodong {
  //   margin-top: 51px;
  width: 300px;
  margin-right: 20px;
  display: flex;
  //   margin-left: 66px;
  .el-icon-search:before {
    color: #fff;
  }
}
.sanhuibutton {
  margin-left: 10px;
  background-color: #d71212 !important;
  color: #fff !important;
}
.sanhuibutton:hover {
  margin-left: 10px;
  background-color: #d71212;
  color: #fff;
}
.activity-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}
.activity-item {
  margin-bottom: 20px;
  position: relative;
  width: 250px;
  height: 300px;
  background: #f3f3f3;
  display: inline-block;
  margin-right: 21px;
  cursor: pointer;
}
.activity-item:nth-child(4n) {
  margin-right: 0;
}
.activity-item-img {
  width: 100%;
  height: 180px;
  position: relative;
}
.activity-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-item-title {
  width: 100%;
  height: 42px;
  color: #f86532;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.activity-item-info {
  padding: 10px;
}
.activity-item-desc {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-time {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-location {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-item-btn {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}
.activity-item-price {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #f86532;
  background: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
}
.el-check-tag {
  margin-bottom: 10px;
}
.activity-condition {
  display: flex;

  margin-bottom: 20px;
  margin-left: 36px;
  margin-top: 20px;
}

.condition-title {
  font-size: 16px;
  color: #444;
  flex-shrink: 0;
}
.condition-content {
  display: flex;

  margin-left: 20px;
  flex-wrap: wrap;
}
.content {
  box-sizing: border-box;
}
.active-title {
  font-size: 20px;
  font-weight: bold;
}

.active-img {
  width: 480px;
  flex-shrink: 0;
}
.active-img-box {
  width: 100%;
  height: 320px;
}
.active-img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-img-list {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.active-img-item {
  width: 111px;
  height: 73px;
  border: 2px solid #ddd;
  cursor: pointer;
}
.active-img-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-info {
  margin-left: 20px;
  width: 100%;
}
.active-time {
  padding: 10px 15px 0;
  font-size: 16px;
  color: #666;
  display: inline-block;
  width: 500px;
  margin-bottom: 5px;
}
.active-address {
  padding: 10px 15px 0;
  font-size: 16px;
  color: #666;
}
.active-address-notop {
  padding: 0 15px 0;
  font-size: 16px;
  color: #666;
}
.active-content {
  padding: 10px 0 0;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}
.active-price {
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.active-price-box {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.active-price-box span {
  font-size: 14px;
  color: #999;
  margin-left: 5px;
}
.active-price-peo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #666;
  margin-left: 20px;
}
.from {
  margin-top: 20px;
}
.active-other {
  margin-top: 20px;
}
.from-box {
  margin-top: 20px;
  background-color: rgba(248, 101, 50, 0.1);
  padding: 20px 10px;
  border-radius: 4px;
  overflow-x: hidden;
}
.from-box .el-form-item:last-child {
  margin-bottom: 18px;
}
.from-box .el-form-item label {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}
.from-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}
.button-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
}
.active-msg {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-top: 10px;
}
</style>
