import http from '../http'
// 接口地址
let request = "/api"
// get请求
export default{
    getRegister(params){
        return http.post(request+'/apisystem/QnsjUser',params)
    },
    getLogin(params){
        return http.post(request+'/appLogin',params)
    },
    getDictData(dictCode){
        return http.get(request + "/apisystem/data/type/" + dictCode)
    },
    getOpenId(params){ // 获取openid
        return http.get(request+'/moudle/apisystem/wx/ApiWxUser/getOpenid',params)
    },
    getInfo(params){
        return http.get(request+'/QnsjUser/info',{params})
    },
    getLogout(params){
        return http.get(request+'/QnsjUser/logout',{params})
    }

}
