<!-- src/pc/PCPage.vue -->
<template>
  <div>
    <Header v-on:MenuList="handleMenuList"></Header>
    <!-- PC端页面内容 -->
    <div class="about">
      <div class="container2">
        <div>
          <div>
            <div class="bread">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"
                  >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item>网上商城</el-breadcrumb-item>
              </el-breadcrumb>
              <div class="huodong">
                <el-input
                  placeholder="请输入商品名称"
                  v-model="input2"
                  class="input-with-select"
                  clearable
                >
                </el-input>
                <el-button class="sanhuibutton" @click="search">搜索</el-button>
              </div>
            </div>
            <div class="activity-condition">
              <div class="condition-title">商品分类</div>
              <div class="condition-content">
                <el-select
                  v-model="classifyId"
                  placeholder="请选择分类"
                  filterable
                  style="width: 300px"
                  @change="change"
                  clearable
                >
                  <el-option
                    v-for="item in classifyList"
                    :key="item.classifyId"
                    :label="item.classifyName"
                    :value="item.classifyId"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="activity-list">
              <div
                class="activity-item"
                @click="lookinfo(item.storeId)"
                v-for="(item, index) in mallList"
                :key="index"
              >
                <div class="activity-item-img">
                  <img
                    :src="
                      item.storeImg.substring(4) == '/api'
                        ? item.storeImg
                        : '/api' + item.storeImg
                    "
                    alt=""
                  />
                  <div class="activity-item-price">
                    ￥{{ item.arr[0].actualPrice }}
                  </div>
                </div>
                <div class="activity-item-info">
                  <div class="activity-item-title">{{ item.storeTitle }}</div>
                  <div class="activity-item-desc">销量：{{ item.storeXl }}</div>
                  <!-- <div class="activity-item-time">数量：{{item.sotreNum}}</div> -->
                  <!--<div class="activity-item-location" title="111111111">
                    111111111111111
                  </div> -->
                </div>
              </div>
            </div>
            <el-empty
              v-if="this.mallList.length == 0"
              description="暂无数据"
            ></el-empty>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// 页面逻辑
import Header from "@/components/header.vue"; // 引入头部组件
import breadcrumbs from "@/components/Breadcrumbs.vue"; // 引入面包屑组件
import LatestActivities from "@/components/LatestActivities.vue"; // 引入最新活动组件
import Footer from "@/components/Footer.vue";
import mobileHeader from "@/components/mobileHeader.vue"; // 引入头部组件
import navs from "@/components/nav.vue"; // 引入导航组件
let img = require("@/static/img/banner.jpg"); // 引入轮播图图片
import api from "@/common/request/request";
import apiuser from "@/common/request/user";
import imgUrl from "@/common/imgUrl";
import router from "@/router";
import { toRaw } from "@vue/reactivity";

export default {
  // 页面逻辑
  components: {
    Header, // 注册头部组件
    breadcrumbs, // 注册面包屑组件
    LatestActivities, // 注册最新活动组件
    Footer, // 注册底部组件
    mobileHeader, // 注册移动端头部组件
    navs, // 注册导航组件
  },
  data() {
    return {
      eventTitle: "",
      // 页面数据
      input2: "", // 搜索框数据
      activityList: [], // 推荐活动数据
      menuList: [], // 推荐音乐数据
      status: "",
      valueTime: "",
      startHdTime: "",
      stopHdTime: "",
      allTime: true,
      OrderslistGroupBy: [],
      eventDestination: "",

      SxType: "",

      img: img,
      // videoImg: "/api/profile/video/b8988d8ee5a14057ae96bd5a619e3361.avi", // 引入视频图片
      showPopup: false,
      value: "",
      classifyList: [],
      classifyId: "",
      mallList: [],
    };
  },

  watch: {
    $route(to, from) {
      console.log("1111", this.$route.query.classifyId);
      if (this.$route.query.classifyId == 0) {
        this.classifyId = "";
        this.getStoreList(); 
      } else if (this.$route.query.classifyId) {
        this.classifyId = Number(this.$route.query.classifyId);
        this.getStoreList(); // 获取推荐活动数据
      }
    },
  },
  created() {
    this.classifyId = Number(this.$route.query.classifyId) || "";
    this.getMallClassify(); // 获取商品分类数据
    // this.getMallList()
    this.getStoreList();
  },
  mounted() {
    // 页面挂载
  },
  methods: {
    search() {
      this.getStoreList();
    },
    change() {
      this.getStoreList();
    },
    getMallClassify() {
      api.getMallClassify().then((res) => {
        console.log(res);
        this.classifyList = res.data.data;
      });
    },
    getStoreList() {
      api
        .getStoreList({ classifyId: this.classifyId, storeTitle: this.input2 })
        .then((res) => {
          console.log(res);
          this.mallList = res.data.data;
        });
    },
    lookinfo(id) {
      this.$router.push({
        path: "/mall/info",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  &:hover {
    color: #b52024 !important;
  }
}
.about {
  .el-breadcrumb {
    font-size: 16px;
  }
  // background-image: url(../assets/bg.png);
  .container2 {
    min-height: calc(100vh - 290px);
    overflow: auto;
    width: 1080px;
    margin: 0 auto;
    background-color: #fff;
  }
  .bread {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 20px;
    margin-top: 36px;
    margin-left: 10px;
    > span {
      padding-right: 10px;
    }
  }
  .infotitle {
    text-align: center;
    margin-top: 68px;
    font-size: 32px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 34px;
    margin-left: 44px;
    margin-right: 44px;
  }
  .infofeilei {
    display: flex;
    justify-content: space-around;
    margin-top: 18px;
    font-size: 16px;
    color: #858585;
  }
  .infocontent {
    margin-top: 44px;
    margin-left: 48px;
    margin-bottom: 44px;
    margin-right: 44px;
  }
}
.huodong {
  //   margin-top: 51px;
  width: 300px;
  margin-right: 20px;
  display: flex;
  //   margin-left: 66px;
  .el-icon-search:before {
    color: #fff;
  }
}
.sanhuibutton {
  margin-left: 10px;
  background-color: #d71212 !important;
  color: #fff !important;
}
.sanhuibutton:hover {
  margin-left: 10px;
  background-color: #d71212;
  color: #fff;
}
.activity-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}
.activity-item {
  margin-bottom: 20px;
  position: relative;
  width: 250px;
  height: 330px;
  background: #f3f3f3;
  display: inline-block;
  margin-right: 21px;
  cursor: pointer;
}
.activity-item:nth-child(4n) {
  margin-right: 0;
}
.activity-item-img {
  width: 100%;
  height: 250px;
  position: relative;
}
.activity-item-img img {
  // width: 100%;
  // height: 100%;
  height: 250px;
  width: 250px;
  // object-fit: cover;
}
.activity-item-title {
  width: 100%;
  height: 42px;
  color: #f86532;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.activity-item-info {
  padding: 10px;
}
.activity-item-desc {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-time {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-location {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-item-btn {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}
.activity-item-price {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #f86532;
  background: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
}
.el-check-tag {
  margin-bottom: 10px;
}
.activity-condition {
  display: flex;

  margin-bottom: 20px;
  margin-left: 10px !important;
  margin-top: 20px;
}

.condition-title {
  font-size: 16px;
  color: #444;
  flex-shrink: 0;
}
.condition-content {
  display: flex;

  margin-left: 20px;
  flex-wrap: wrap;
}
</style>
