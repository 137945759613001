<script>
import { defineComponent } from "vue";
import Menu from "./menu";
import router from "@/router";
import api from "@/common/request/request";

export default defineComponent({
  name: "header",
  data() {
    return {
      showSubMenuBtn: false,
      dialogVisible: false,
      dialogVisible2: false,
      form: {
        phone: "",
        userPassword: "",
        confirmPassword: "",
      },
      userInfo: null,
      MenuList: Menu,
      currentIndex: null,
      classifyListHd: [],
      classifyListTp: [],
      classifyList: [],
      classifySqList: [],
    };
  },
  mounted() {
    console.log("aaaa", this.MenuList);
  },
  created() {
    this.getClassifyHd();
    this.getClassifyTp();
    this.getClassify();
    this.getClassifySqList();
    // this.dict();
    console.log(process.env, "process.env.VUE_APP_BASE_API");
    if (
      localStorage.getItem("zsjyuserInfo") == undefined ||
      localStorage.getItem("zsjyuserInfo") == null ||
      !localStorage.getItem("zsjyuserInfo") ||
      localStorage.getItem("zsjyuserInfo") === "undefined"
    ) {
      localStorage.setItem("zsjyuserInfo", null);
    }
    if (localStorage.getItem("zsjyuserInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("zsjyuserInfo"));
    }
    console.log("11111", this.userInfo);
    console.log(this.MenuList);
    for (let i = 0; i < this.MenuList.length; i++) {
      console.log(this.$route.query.status);
      let num = this.$route.query.status;
      if (num && num <= 4) {
        this.currentIndex = 2;
        break;
      }
      if (num && num > 4) {
        this.currentIndex = 1;
        break;
      }
      if (
        this.MenuList[i].link.split("/")[1] === this.$route.path.split("/")[1]
      ) {
        this.currentIndex = i;
        break;
      }
    }
  },
  watch: {
    $route(to, from) {
      let userInfo = JSON.parse(localStorage.getItem("zsjyuserInfo"));
      console.log(userInfo);
      if (userInfo) {
        this.userInfo = userInfo;
      }
      if (this.$route.query.status) {
        let num = this.$route.query.status;
        if (num && num <= 5) {
          this.currentIndex = 2;
        }
        if (num && num > 5) {
          this.currentIndex = 1;
        }
      }
    },
  },
  methods: {
    getClassifySqList() {
      api.getClassifySq().then((res) => {
        this.classifySqList = res.data.data;
        var arr = [];
        for (var i = 0; i < this.classifySqList.length; i++) {
          arr.push({
            name: res.data.data[i].classifyName,
            link: "/district?classifyId=" + res.data.data[i].classifyId,
            status: res.data.data[i].classifyId,
          });
        }
        this.MenuList[4].subMenu = arr;
      });
    },
    //  getApplicationClassify() {
    //       api.getApplicationClassifyHd().then((res) => {
    //         console.log(res);
    //         this.classifyHdList = res.data.data;
    //         this.data.classifyId = this.classifyHdList[0].classifyId;
    //         this.getActivityList();
    //       });
    //     },
    getClassifyHd() {
      api.getApplicationClassifyHd().then((res) => {
        console.log(res);
        this.classifyListHd = res.data.data;
        console.log("分类列表", this.classifyListHd);
        var arr = [];
        for (var i = 0; i < this.classifyListHd.length; i++) {
          arr.push({
            name: res.data.data[i].classifyName,
            link: "/application?classifyId=" + res.data.data[i].classifyId,
            status: res.data.data[i].classifyId,
          });
        }
        var arr2 = [];
        for (var i = 0; i < this.classifyListHd.length; i++) {
          arr2.push({
            name: res.data.data[i].classifyName,
            link: "/players?classifyId=" + res.data.data[i].classifyId,
            status: res.data.data[i].classifyId,
          });
        }
        this.MenuList[6].subMenu = arr;
        this.MenuList[5].subMenu = arr2;
      });
    },
    getClassifyTp() {
      api.getApplicationClassifyHd().then((res) => {
        console.log(res);
        this.classifyListTp = res.data.data;
        var arr = [];
        for (var i = 0; i < this.classifyListTp.length; i++) {
          arr.push({
            name: res.data.data[i].classifyName,
            link: "/vote?classifyId=" + res.data.data[i].classifyId,
            status: res.data.data[i].classifyId,
          });
        }
        this.MenuList[7].subMenu = arr;
      });
    },
    getClassify() {
      api.getMallClassify().then((res) => {
        console.log(res);
        this.classifyList = res.data.data;
        var arr = [];
        if (this.classifyList.length <= 5) {
          for (var i = 0; i < this.classifyList.length; i++) {
            arr.push({
              name: res.data.data[i].classifyName,
              link: "/mall?classifyId=" + res.data.data[i].classifyId,
              status: res.data.data[i].classifyId,
            });
          }
          // return false;
        }
        if (this.classifyList.length > 5) {
          for (var i = 0; i < 5; i++) {
            arr.push({
              name: res.data.data[i].classifyName,
              link: "/mall?classifyId=" + res.data.data[i].classifyId,
              status: res.data.data[i].classifyId,
            });
          }
          arr.push({
            name: "查看更多",
            link: "/mall?classifyId=" + "0",
            status: "0",
          });
        }
        this.MenuList[9].subMenu = arr;
      });
    },
    enroll() {
      console.log("enroll");
      this.dialogVisible = true;
    },
    login() {
      console.log("login");
      this.dialogVisible2 = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleClose2() {
      this.dialogVisible2 = false;
    },
    Confirm() {
      console.log("Confirm");
      if (
        this.form.phone === "" ||
        this.form.userPassword === "" ||
        this.form.confirmPassword === ""
      ) {
        this.$message.error("请输入账号或密码");
        return;
      }
      if (
        this.form.userPassword &&
        !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\W_]).{6,12}$/.test(
          this.form.userPassword
        )
      ) {
        this.$message.error("请输入包含字母，数字，特殊字符在内的6-12位的密码");
        return;
      }
      if (this.form.userPassword !== this.form.confirmPassword) {
        this.$message.error("两次密码输入不一致");
        return;
      }
      if (!/^[0-9]*$/.test(this.form.phone) || this.form.phone.length !== 11) {
        this.$message.error("用户名请输入手机号");
        return;
      }
      this.dialogVisible = false;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api.getUserRegister(this.form).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success("注册成功");
          this.form.userPassword = "";
          this.form.confirmPassword = "";
        } else {
          this.$message.error(res.data.msg);
        }
        loading.close();
      });
    },
    Confirm2() {
      console.log("Confirm2");
      if (this.form.phone === "" || this.form.userPassword === "") {
        this.$message.error("请输入账号或密码");
        return;
      }
      if (!/^[0-9]*$/.test(this.form.phone) || this.form.phone.length !== 11) {
        this.$message.error("用户名请输入手机号");
        return;
      }
      this.dialogVisible2 = false;
      api
        .getUserLogin({
          phone: this.form.phone,
          userPassword: this.form.userPassword,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            let userInfo = JSON.stringify(res.data.data);
            localStorage.setItem("zsjyuserInfo", userInfo);
            // this.userInfo = res.data.data;
            this.$message.success("登录成功");

            router.go(0);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    navTo(url, subMenu) {
      if (!subMenu) {
        router.push(url);
      }
    },
    subNavTo(url) {
      router.push(url);
    },
    navOut() {
      console.log("navOut");
      if (this.$route.path != "/") {
        this.$router.push("/");
      } else {
        router.go(0);
      }
      localStorage.removeItem("zsjyuserInfo");
      // this.userInfo = null;
    },
  },
});
</script>

<template>
  <div class="header">
    <div class="topLine">
      <div class="main flexBetween">
        <div class="topLineLeft">欢迎访问中视佳音网</div>
        <div class="topLineRight" v-if="!userInfo">
          <div @click="enroll" class="loginBtn">注册</div>
          |
          <div @click="login" class="loginBtn">登录</div>
        </div>
        <div class="topLineRight" v-if="userInfo">
          <el-dropdown :hide-on-click="false">
            <div class="loginBtn">{{ userInfo.userUsername }}</div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click.native="navTo('/user')"
                  >个人中心</el-dropdown-item
                >
                <!--                <el-dropdown-item>消息通知</el-dropdown-item>-->
                <el-dropdown-item @click.native="navOut()"
                  >退出</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- src="../static/img/header.png" -->
    <div class="topPic">
      <img class="topPicImg" alt />
      <div class="topPicTitle main">
        <div class="topPicTitle_left">
          <img src="../static/img/logo5.png" alt />
        </div>
        <div class="topPicTitle_right">
          <span>中视佳音网</span>
          <span>专注智能影像技术/塑造企业品牌形象/记录生活美好时光</span>
        </div>
      </div>
    </div>
    <el-affix offset-top="0">
      <div class="tab">
        <div class="tabBox main">
          <div
            class="tabItem"
            v-for="(item, index) in MenuList"
            :class="index === currentIndex ? 'active' : ''"
          >
            <div class="tabName" @click="navTo(item.link, item.subMenu)">
              {{ item.name }}
            </div>
            <div class="tabItem_ul_box">
              <div
                class="tabItem_ul"
                v-if="item.subMenu"
                v-for="items in item.subMenu"
                @click="subNavTo(items.link)"
              >
                <div class="tabItem_li" :title="items.name">
                  {{ items.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-affix>
    <el-dialog
      v-model="dialogVisible"
      title="注册"
      width="550"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-form-item
          label="用户名"
          :rules="[{ required: true, message: '请输入用户名' }]"
        >
          <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item
          label="密码"
          :rules="[{ required: true, message: '请输入密码' }]"
        >
          <el-input
            type="password"
            v-model="form.userPassword"
            placeholder="请输入包含字母，数字，特殊字符在内的6-12位的密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          :rules="[
            { required: true, message: '请输入确认密码', trigger: 'blur' },
          ]"
        >
          <el-input
            type="password"
            v-model="form.confirmPassword"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="Confirm">确认</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="dialogVisible2"
      title="登录"
      width="500"
      :before-close="handleClose2"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item
          label="用户名"
          :rules="[{ required: true, message: '请输入用户名' }]"
        >
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item
          label="密码"
          :rules="[{ required: true, message: '请输入密码' }]"
        >
          <el-input
            type="password"
            v-model="form.userPassword"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="kefxx">
        <div>中视佳音网</div>
        <div>修改密码联系:13718128521</div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取消</el-button>
          <el-button type="primary" @click="Confirm2">确认</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<style>
.messageIndex {
  z-index: 3000 !important;
}
</style>
<style scoped lang="scss">
.topLine {
  height: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.4);
  font-size: 15px;
}

.topLineRight {
  display: flex;
}

.topPic {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
  height: 200px;
  background-image: url("../static/img/header.png");
}

.topPicImg {
  width: 100%;
  /* height: 200px; */
}

.topPicTitle {
  position: absolute;
  top: 60px;
  left: calc(50% - 1080px / 2);
  margin: 0 auto;
  color: #fff;
  font-size: 22px;
  display: flex;

  align-items: center;
}

.topPicTitle_left {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 10px;
}

.topPicTitle_left img {
  width: 100%;
  height: 100%;
}

.topPicTitle_right span {
  display: block;
}

.topPicTitle_right span:first-child {
  font-size: 20px;
  font-weight: bold;
}

.topPicTitle_right span:last-child {
  font-size: 16px;
  margin-top: 5px;
}

.header {
  height: 200px;
  position: relative;
}

.tab {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  z-index: 1;
  background-color: #d61415;
}

.tabItem {
  display: inline-block;
  line-height: 45px;
  width: 108px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.tabItem:hover .tabItem_ul_box {
  display: block !important;
  z-index: 9999999 !important;
}

.tabItem_ul_box {
  position: absolute;
  top: 45px;
  // left: -10px;
  width: 100px;
  background-color: #f86532;
  display: none;
  z-index: 9999999;
}

.tabItem_ul {
  top: 45px;
  width: 108px;
  background-color: #f86532;
  z-index: 9999999;
}

.tabItem_li {
  line-height: 45px;
  padding: 0 10px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 9999999;
}

.tabItem:hover {
  background-color: #fe8340;
}

.tabItem_li:hover {
  background-color: #fe8340;
}

.active {
  background-color: #fe8340 !important;
}

.el-affix {
  height: 45px;
}

.loginBtn {
  display: inline-block;

  line-height: 32px;
  text-align: center;

  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px;
  font-size: 14px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none;
  &:hover {
    border: none;
  }
}

.dialog-footer {
  text-align: right;
}
.kefxx {
  text-align: center;
  color: #adadad;
  font-size: 12px;
  line-height: 30px;
}
</style>
