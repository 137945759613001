// import { get } from 'core-js/core/dict'
// import { get } from 'core-js/core/dict'
import http from '../http'
// 接口地址
let request = "/api"
    // get请求
export default {
    getActivityList(params) {
        return http.get(request + '/pcsystem/ZsjyArticle/list', params)
    },
    getActivityInfo(params){
        return http.get(request + '/pcsystem/ZsjyArticle/' + params, {})
    },
    // /api/pcsystem/ZsjyVote/list 投票活动管理
    getVoteList(params) { 
        return http.get(request + '/pcsystem/ZsjyVote/list', params)
    },
    // getZsjyCompetition
    getZsjyCompetition(params) { 
        return http.get(request + '/pcsystem/ZsjyCompetition/list', params)
    },
    getZsjyCompetitionInfo(params){
        return http.get(request + '/pcsystem/ZsjyCompetition/' + params, {})
    },
    // /api/pcsystem/ZsjyActivity/listall 投票活动管理
    getApplicationListAll(params) { 
        return http.get(request + '/pcsystem/ZsjyActivity/listall', params)
    },
    getApplicationInfo(params){
        return http.get(request + '/pcsystem/ZsjyActivity/' + params, {})
    },
    // /api/pcsystem/ZsjyVote/listall 投票活动管理
    getVoteListAll(params) { 
        return http.get(request + '/pcsystem/ZsjyVote/listall', params)
    },
    getVoteInfo(params){
        return http.get(request + '/pcsystem/ZsjyVote/' + params, {})
    },
    // /api/pcsystem/ZsjyClassify/listallHd 活动分类管理
    getApplicationClassifyHd(params){
        return http.get(request + '/pcsystem/ZsjyClassify/listallHd', params)
    },
    // /api/pcsystem/ZsjyClassify/listallTp 投票分类管理
    getVoteClassifyTp(params){
        return http.get(request + '/pcsystem/ZsjyClassify/listallTp', params)
    },
    // 赛区分类管理
    getClassifySq(params){
        return http.get(request + '/pcsystem/ZsjyClassify/listallSq', params)
    },
    // /api/pcsystem/ZsjyClassify/listall 商城分类管理
    getMallClassify(params){
        return http.get(request + '/pcsystem/ZsjyClassify/listall', params)
    },
    // /api/pcsystem/ZsjyPlayer/listall 参与者管理
    getPlayerList(params) { 
        return http.get(request + '/pcsystem/ZsjyPlayer/listall', params)
    },
    // /api/pcsystem/ZsjyPlayer/{playerId} 参与者详情
    getPlayerInfo(params){
        return http.get(request + '/pcsystem/ZsjyPlayer/' + params, {})
    },
    // /api/pcsystem/ZsjyAppUser 用户注册
    getUserRegister(params) { 
        return http.post(request + '/pcsystem/ZsjyAppUser', params)
    },
    // /api/pcsystem/ZsjyAppUser/login 用户登录
    getUserLogin(params) { 
        return http.post(request + '/pcsystem/ZsjyAppUser/login', params)
    },
    // /api/pcsystem/ZsjyVoteLog   新增投票
    addVoteLog(params) { 
        return http.post(request + '/pcsystem/ZsjyVoteLog', params)
    },
    // /api/pcsystem/ZsjyActivityLog   报名
    addActivityLog(params) { 
        return http.post(request + '/pcsystem/ZsjyActivityLog', params)
    },
    // /api/pcsystem/ZsjyPerson/listall 人员列表
    getPersonList(params){
        return http.get(request + '/pcsystem/ZsjyPerson/listall', params)
    },
    // /api/pcsystem/ZsjyStore/listall
    getStoreList(params){
        return http.get(request + '/pcsystem/ZsjyStore/listall', params)
    },
    // /api/pcsystem/ZsjyStore/{storeId}
    getStoreInfo(params){
        return http.get(request + '/pcsystem/ZsjyStore/' + params, {})
    },
    // /api/apisystem/ZsjyOrder
    addorder(params){
        return http.post(request + '/pcsystem/ZsjyOrder', params)
    },
    // /api/pcsystem/ZsjyAppUserAddress/listall
    getAddressList(params){
        return http.get(request + '/pcsystem/ZsjyAppUserAddress/listall', params)
    },
    // /api/moudle/apisystem/wx/ApiWxUser/orderSetPay
    orderpay(params){
        return http.post(request + '/moudle/apisystem/wx/ApiWxUser/orderSetPay', params)
    },
    // /api/pcsystem/ZsjyAppUser/{userUsername}
    getUserInfo(params){
        return http.get(request + '/pcsystem/ZsjyAppUser/' + params, {})
    },
    // /api/pcsystem/ZsjyAppUser/editQxAppUser
    updateUserInfo(params){
        return http.post(request + '/pcsystem/ZsjyAppUser/editQxAppUser', params)
    },
    // /api/pcsystem/ZsjyActivityLog/listall
    getApplicationList(params){
        return http.get(request + '/pcsystem/ZsjyActivityLog/list', params)
    },
    // /api/pcsystem/ZsjyActivityLog/{logId}
    getApplicationInfoById(params){
        return http.get(request + '/pcsystem/ZsjyActivityLog/' + params, {})
    },
    // /api/pcsystem/ZsjyVoteLog/listall
    getVoteLogList(params){
        return http.get(request + '/pcsystem/ZsjyVoteLog/list', params)
    },
    // /api/pcsystem/ZsjyOrder/listall
    getOrderList(params){
        return http.get(request + '/pcsystem/ZsjyOrder/list', params)
    },
    // /api/pcsystem/ZsjyVoteLog/{logId}
    getVoteLogInfo(params){
        return http.get(request + '/pcsystem/ZsjyVoteLog/' + params, {})
    },
    // /api/pcsystem/ZsjyOrder/{orderId}
    getOrderInfo(params){
        return http.get(request + '/pcsystem/ZsjyOrder/' + params, {})
    },
    // http://localhost/api/information/ZsjyNotice/list?pageNum=1&pageSize=10&configKey=applicate_notice
    // /pcsystem/ZsjyNotice/configKey/{configKey}
    getNoticeList(params){
        return http.get(request + '/pcsystem/ZsjyNotice/configKey/' + params,{})
    },
    // /api/pcsystem/ZsjyOrder
    editOrder(params){
        return http.put(request + '/pcsystem/ZsjyOrder', params)
    },
    // ZsjyPerson// /api/pcsystem/ZsjyPerson/listall
    getPersonListAll(params){
        return http.get(request + '/pcsystem/ZsjyPerson/listall', params)
    },
    getPersonList(params){
        return http.get(request + '/pcsystem/ZsjyPerson/list', params)
    },
    getPersonInfo(params){
        return http.get(request + '/pcsystem/ZsjyPerson/' + params,{})
    },
    // /api/pcsystem/area/listAll/api/pcsystem/area/treeselectArea
    getRegionList(params){
        return http.get(request + '/pcsystem/area/treeselectArea', params) 
    },
    // /ZsjySerial/treeselectArea
    getRegionList2(params){
        return http.get(request + '/pcsystem/ZsjySerial/treeselectArea', params) 
    }
}