<template>
  <div>
    <Header v-on:MenuList="handleMenuList"></Header>
    <!-- PC端页面内容 -->
    <div class="about" v-if="mallInfo">
      <div class="container2">
        <div>
          <div>
            <div class="bread">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"
                  >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item :to="{ path: '/mall' }"
                  >网上商城</el-breadcrumb-item
                >
                <!-- <el-breadcrumb-item>{{
                  mallInfo.storeTitle
                }}</el-breadcrumb-item> -->
                <el-breadcrumb-item><div style="max-width: 700px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;font-size: 16px;line-height: 16px;">{{this. mallInfo.storeTitle}}</div></el-breadcrumb-item>

              </el-breadcrumb>
            </div>
            <div class="content main" style="margin: 20px 0 20px 0px">
              <div
                class="active-title"
                style="margin-left: 10px; margin-top: 20px"
              >
                {{ mallInfo.storeTitle }}
              </div>
              <div class="active-content">
                <div class="active-img">
                  <el-carousel style="width: 300px; height: 300px">
                    <el-carousel-item
                      v-for="(item, index) in mallInfo.storeImgs"
                      :key="item"
                    >
                      <img
                        style="width: 300px; height: 300px"
                        alt
                        :src="
                          item.substring(0, 4) == '/api' ? item : '/api' + item
                        "
                      />
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <div class="active-info">
                  <div class="active-price">
                    <div class="active-price-box">
                      价格：
                      <span>{{ specInfo? specInfo.actualPrice : mallInfo.arr[0].actualPrice }}</span>
                    </div>
                    <div class="active-price-peo">
                      <span>销量:{{ mallInfo.storeXl }}</span>
                    </div>
                  </div>
                  <div class="active-time">
                    选择规格：
                    <el-select
                      v-model="specId"
                      placeholder="选择规格"
                      style="width: 240px"
                      @change="change"
                      clearable
                    >
                      <el-option
                        v-for="item in mallInfo.arr"
                        :key="item.specId"
                        :label="item.storePicture"
                        :value="item.specId"
                      />
                    </el-select>
                  </div>
                  <div class="active-time" v-if="specInfo">库存：{{ specInfo.storeStock }}</div>
                  <el-divider border-style="dashed" />
                  <!-- <div class="active-time">数量：{{ mallInfo.sotreNum }}</div> -->
                  <div class="active-time">运费：{{ mallInfo.storeYf }}</div>
                  <!-- <el-divider border-style="dashed" /> -->

                  <!-- <div class="active-address-notop">
                    集合地：{{ applicationInfo.activityJhd }}
                  </div>
                  <div class="active-address">
                    目的地：{{ applicationInfo.activityMdd }}
                  </div> -->
                  <div class="active-address" style="margin-top: 10px">
                    <el-button type="primary" size="large" @click="goumai()"
                      >我要购买</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 左侧内容 -->
            <div class="contentView main">
              <div
                style="
                  padding-left: 10px;
                  margin-top: 20px;
                  margin-bottom: 20px;
                  font-size: 20px;
                  font-weight: 700;
                "
              >
                商品介绍
              </div>
              <div
                v-html="mallInfo.storeContent"
                style="
                  text-indent: 2em;
                  line-height: 1.5em;
                  font-size: 16px;
                  color: #333;
                  padding: 0 10px;
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-empty style="min-height: calc(100vh - 290px);" v-else description="暂无数据"></el-empty>
    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// 页面逻辑
import Header from "@/components/header.vue"; // 引入头部组件
import breadcrumbs from "@/components/Breadcrumbs.vue"; // 引入面包屑组件
import LatestActivities from "@/components/LatestActivities.vue"; // 引入最新活动组件
import Footer from "@/components/Footer.vue";
import mobileHeader from "@/components/mobileHeader.vue"; // 引入头部组件
import navs from "@/components/nav.vue"; // 引入导航组件
let img = require("@/static/img/banner.jpg"); // 引入轮播图图片
import api from "@/common/request/request";
import apiuser from "@/common/request/user";
import imgUrl from "@/common/imgUrl";
import router from "@/router";
import { toRaw } from "@vue/reactivity";

export default {
  // 页面逻辑
  components: {
    Header, // 注册头部组件
    breadcrumbs, // 注册面包屑组件
    LatestActivities, // 注册最新活动组件
    Footer, // 注册底部组件
    mobileHeader, // 注册移动端头部组件
    navs, // 注册导航组件
  },
  data() {
    return {
      mallInfo: null, // 商城信息
      specId: "", // 规格id
      specInfo:'', // 规格信息
    };
  },

  watch: {
    // $route(to, from) {
    //     if (this.$route.query.status) {
    //         this.status = this.$route.query.status
    //         this.getActivityLists() // 获取推荐活动数据
    //     }
    // }
  },
  created() {
    let id = this.$route.query.id;
    this.getMallInfo(id);
  },
  mounted() {
    // 页面挂载
  },
  methods: {
    change(e) {
      console.log(e);
      this.specId = e;
      if(this.specId){
        this.specInfo = this.mallInfo.arr.find(item => item.specId == e)
      }else{
        this.specInfo=''
      }
      console.log(this.specInfo)
    },
    getMallInfo(id) {
      api.getStoreInfo(id).then((res) => {
        console.log(res);
        this.mallInfo = res.data.data;
        this.mallInfo.storeImgs = this.mallInfo.storeImgs.split(",");
        console.log(this.mallInfo);
      });
    },
    goumai(){
      if (
        !localStorage.getItem("zsjyuserInfo") ||
        localStorage.getItem("zsjyuserInfo") == null ||
        localStorage.getItem("zsjyuserInfo") === "null"
      ) {
        this.$message.error("请先登录");
        return false;
      }
      if(this.specId==''){
        this.$message.error("请选择规格");
      }else{
      this.$router.push({
        path: '/mall/pay',
        query: {
          id: this.mallInfo.storeId,
          specId:this.specId
        }
      })
    }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  &:hover {
    color: #b52024 !important;
  }
}
.about {
  .el-breadcrumb {
    font-size: 16px;
  }
  // background-image: url(../assets/bg.png);
  .container2 {
    min-height: calc(100vh - 290px);
    overflow: auto;
    width: 1080px;
    margin: 0 auto;
    background-color: #fff;
  }
  .bread {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 20px;
    margin-top: 36px;
    margin-left: 10px;
    > span {
      padding-right: 10px;
    }
  }
  .infotitle {
    text-align: center;
    margin-top: 68px;
    font-size: 32px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 34px;
    margin-left: 44px;
    margin-right: 44px;
  }
  .infofeilei {
    display: flex;
    justify-content: space-around;
    margin-top: 18px;
    font-size: 16px;
    color: #858585;
  }
  .infocontent {
    margin-top: 44px;
    margin-left: 48px;
    margin-bottom: 44px;
    margin-right: 44px;
  }
}
.huodong {
  //   margin-top: 51px;
  width: 300px;
  margin-right: 20px;
  display: flex;
  //   margin-left: 66px;
  .el-icon-search:before {
    color: #fff;
  }
}
.sanhuibutton {
  margin-left: 10px;
  background-color: #d71212 !important;
  color: #fff !important;
}
.sanhuibutton:hover {
  margin-left: 10px;
  background-color: #d71212;
  color: #fff;
}
.activity-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}
.activity-item {
  margin-bottom: 20px;
  position: relative;
  width: 250px;
  height: 300px;
  background: #f3f3f3;
  display: inline-block;
  margin-right: 21px;
  cursor: pointer;
}
.activity-item:nth-child(4n) {
  margin-right: 0;
}
.activity-item-img {
  width: 100%;
  height: 180px;
  position: relative;
}
.activity-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-item-title {
  width: 100%;
  height: 42px;
  color: #f86532;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.activity-item-info {
  padding: 10px;
}
.activity-item-desc {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-time {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-location {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-item-btn {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}
.activity-item-price {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #f86532;
  background: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
}
.el-check-tag {
  margin-bottom: 10px;
}
.activity-condition {
  display: flex;

  margin-bottom: 20px;
  margin-left: 36px;
  margin-top: 20px;
}

.condition-title {
  font-size: 16px;
  color: #444;
  flex-shrink: 0;
}
.condition-content {
  display: flex;

  margin-left: 20px;
  flex-wrap: wrap;
}
.content {
  box-sizing: border-box;
}
.active-title {
  font-size: 20px;
  font-weight: bold;
}
.active-content {
  display: flex;
  margin-left: 10px;
}
.active-img {
  width: 300px;
  flex-shrink: 0;
}
.active-img-box {
  width: 100%;
  height: 320px;
}
.active-img-box img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}
.active-img-list {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
}
.active-img-item {
  width: 116px;
  height: 73px;
  border: 2px solid #ddd;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
}
.active-img-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-info {
  margin-left: 20px;
  width: 100%;
}
.active-time {
  padding: 10px 15px 0;
  font-size: 16px;
  color: #666;
}
.active-address {
  padding: 10px 15px 0;
  font-size: 16px;
  color: #666;
}
.active-address-notop {
  padding: 0 15px 0;
  font-size: 16px;
  color: #666;
}
.active-content {
  padding: 10px 0 0;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}
.active-price {
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active-price-box {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.active-price-peo {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
}
.contentView {
  margin-top: 20px;
}
.contentViewBox {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}
.img {
  max-width: 100%;
}
.active1 {
  border: 2px solid #409eff;
}
.active-price-box span {
  color: #ff4949;
}
</style>
