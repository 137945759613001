<!-- src/pc/PCPage.vue -->
<template>
  <div>
    <Header></Header>
    <!-- PC端页面内容 -->
    <!-- <div class="main">
      <el-carousel height="400px">
        <el-carousel-item v-for="(item, index) in bannerList" :key="item">
          <img :src="item.rotationFile" alt="" class="bemImg">
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <div class="main">
      <div class="home">
        <div class="container">
          <el-row :gutter="24" style="padding-top: 30px">
            <el-col :span="12" style="position: relative">
              <el-tabs v-model="activeName">
                <el-tab-pane label="最新动态" name="first">
                  <el-empty
                    v-if="this.activityList.length == 0"
                    description="暂无数据"
                  ></el-empty>
                  <ul class="dongtailist">
                    <li
                      @click="lookDongtaiinfo(item)"
                      v-for="item in activityList"
                      :key="item.articleId"
                    >
                      <div>
                        <img src="../../assets/arrow.png" alt="" />
                        <div>{{ item.articleTitle }}</div>
                      </div>
                      <div>{{ item.createTime }}</div>
                    </li>
                  </ul>
                </el-tab-pane>
              </el-tabs>
              <div class="lookmore" @click="lookdongtai">
                <span>查看更多></span>
              </div>
            </el-col>
            <el-col :span="12" style="position: relative">
              <el-tabs v-model="activeName2">
                <el-tab-pane label="通知公告" name="first">
                  <el-empty
                    v-if="this.noticeList.length == 0"
                    description="暂无数据"
                  ></el-empty>
                  <ul class="dongtailist">
                    <li
                      @click="lookDongtaiinfo(item)"
                      v-for="(item, index) in noticeList"
                      :key="index"
                    >
                      <div>
                        <img src="../../assets/arrow.png" alt="" />
                        <div>{{ item.articleTitle }}</div>
                      </div>
                      <div>{{ item.createTime }}</div>
                    </li>
                  </ul>
                </el-tab-pane>
              </el-tabs>
              <div class="lookmore" @click="lookNotice">
                <span>查看更多></span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" style="margin-top: 30px">
            <el-col :span="12" style="position: relative">
              <el-tabs v-model="activeName3">
                <el-tab-pane label="活动介绍" name="first">
                  <el-empty
                    v-if="this.actList.length == 0"
                    description="暂无数据"
                  ></el-empty>
                  <ul v-else>
                    <div class="firstdangjian">
                      <img
                        :src="
                          this.actList[0].articleImg.substring(0, 4) == '/api'
                            ? this.actList[0].articleImg
                            : '/api' + this.actList[0].articleImg
                        "
                        alt=""
                        width="180px"
                        height="120px"
                        style="margin-top: 20px"
                      />
                      <div>
                        <p>{{ this.actList[0].articleTitle }}</p>
                        <p v-html="this.actList[0].articleContent"></p>
                        <div @click="lookDongtaiinfo(this.actList[0])">
                          <span>查看更多></span>
                        </div>
                      </div>
                    </div>
                    <li
                      class="dangjianlist"
                      @click="lookDongtaiinfo(item)"
                      v-for="(item, index) in actList"
                      :key="index"
                    >
                      <div v-if="index > 0">
                        <img src="../../assets/arrow.png" alt="" />
                        <div>{{ item.articleTitle }}</div>
                      </div>
                      <div v-if="index > 0">{{ item.createTime }}</div>
                    </li>
                  </ul>
                </el-tab-pane>
              </el-tabs>
              <div @click="lookAct()" class="lookmore">
                <span>查看更多></span>
              </div>
            </el-col>
            <el-col :span="12" style="position: relative">
              <el-tabs
                v-model="activeName4"
                style="height: 330px; margin-bottom: 49px"
              >
                <el-tab-pane label="赛区风采" name="first">
                  <el-empty
                    v-if="this.districtList.length == 0"
                    description="暂无数据"
                  ></el-empty>
                  <ul class="qitalist">
                    <li
                      @click="lookcominfo(item)"
                      v-for="(item, index) in districtList"
                      :key="index"
                    >
                      <div style="display: flex; align-items: center">
                        <img src="../../assets/arrow.png" alt="" />
                        <div>{{ item.articleTitle }}</div>
                      </div>
                      <div>{{ item.createTime }}</div>
                    </li>
                  </ul>
                </el-tab-pane>
                <el-tab-pane label="选手展示" name="second">
                  <el-empty
                    v-if="this.playerList.length == 0"
                    description="暂无数据"
                  ></el-empty>
                  <ul class="qitalist" v-else>
                    <li
                      @click="lookPlayerinfo(item)"
                      v-for="(item, index) in playerList"
                      :key="index"
                    >
                      <div style="display: flex; align-items: center">
                        <img src="../../assets/arrow.png" alt="" />
                        <div>{{ item.personName }}</div>
                      </div>
                      <!-- <div>{{item.createTime}}</div> -->
                    </li>
                  </ul>
                </el-tab-pane>
              </el-tabs>
              <div @click="lookplayer()" class="lookmore">
                <span>查看更多></span>
              </div>
              <div style="position: relative">
                <el-tabs v-model="activeName5" style="height: 330px">
                  <!-- <el-tab-pane label="宣传报道" name="first">
                    <el-empty
                  v-if="this.mediaList.length == 0"
                  description="暂无数据"
                ></el-empty>
                    <ul class="qitalist">
                      <li @click="lookcominfo(item)" v-for="(item, index) in mediaList" :key="index">
                        <div>
                          <img src="../../assets/arrow.png" alt="" />
                          <div>{{item.articleTitle}}</div>
                        </div>
                        <div>{{item.createTime}}</div>
                      </li>
                    </ul>
                  </el-tab-pane> -->
                  <el-tab-pane label="企业展示" name="first">
                    <el-empty
                      v-if="this.companyList.length == 0"
                      description="暂无数据"
                    ></el-empty>
                    <ul class="qitalist">
                      <li
                        @click="lookDongtaiinfo(item)"
                        v-for="(item, index) in companyList"
                        :key="index"
                      >
                        <div>
                          <img src="../../assets/arrow.png" alt="" />
                          <div>{{ item.articleTitle }}</div>
                        </div>
                        <div>{{ item.createTime }}</div>
                      </li>
                    </ul>
                  </el-tab-pane>
                </el-tabs>
                <div @click="lookmedia()" class="lookmore">
                  <span>查看更多></span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="width: 1080px">
        <img src="../../assets/banner2.jpg" alt="" width="1080px" />
      </div>
      <div class="activity" style="margin-top: 20px">
        <div class="activity-title flexBetween">
          <div class="title">网上报名</div>
          <div class="more" @click="lookMoreAct()">更多</div>
        </div>
        <div class="activity-list">
          <div
            class="activity-item"
            v-for="(item, index) in applicationList"
            :key="item.activityId"
            @click="lookApplicationInfo(item.activityId)"
          >
            <div class="activity-item-img">
              <img
                :src="
                  item.activityImgs[0].substring(0, 4) == '/api'
                    ? item.activityImgs[0]
                    : '/api' + item.activityImgs[0]
                "
                alt=""
              />
              <!-- <img :src="item.activityImgs[0]" alt="" /> -->

              <!-- <div class="activity-item-price">￥1111111111</div> -->
            </div>
            <div class="activity-item-info">
              <div class="activity-item-title">
                {{ item.activityTitle }}
              </div>
              <div class="activity-item-desc">
                开始时间：{{ item.startTime }}
              </div>
              <div class="activity-item-time">
                结束时间：{{ item.stopTime }}
              </div>
              <!-- <div class="activity-item-location" title="111111111">111111111111111</div> -->
            </div>
          </div>
        </div>
        <el-empty
          v-if="this.applicationList.length == 0"
          description="暂无数据"
        ></el-empty>
      </div>
      <div class="activity">
        <div class="activity-title flexBetween">
          <div class="title">网上投票</div>
          <div class="more" @click="lookMoreVote()">更多</div>
        </div>
        <div class="activity-list">
          <div
            class="activity-item"
            @click="lookInfo(item.activityId)"
            v-for="(item, index) in voteList"
            :key="item.activityId"
          >
            <div class="activity-item-img">
              <img
                :src="
                  item.activityImgs[0].substring(0, 4) == '/api'
                    ? item.activityImgs[0]
                    : '/api' + item.activityImgs[0]
                "
                alt=""
              />
              <!-- <img :src="item.activityImgs[0]" alt="" /> -->

              <!-- <div class="activity-item-price">￥1111111111</div> -->
            </div>
            <div class="activity-item-info">
              <div class="activity-item-title">
                {{ item.activityTitle }}
              </div>
              <div class="activity-item-desc">
                开始时间：{{ item.startTime }}
              </div>
              <div class="activity-item-time">
                结束时间：{{ item.stopTime }}
              </div>
              <!-- <div class="activity-item-location" title="111111111">111111111111111</div> -->
            </div>
          </div>
        </div>
        <el-empty
          v-if="this.voteList.length == 0"
          description="暂无数据"
        ></el-empty>
      </div>
      <div>
        <div class="activity">
          <div class="activity-title flexBetween">
            <div class="title">网上商城</div>
            <div class="more" @click="lookMoreMall()">更多</div>
          </div>
          <div class="activity-list">
            <div
              class="activity-item"
              style="height: 330px"
              @click="lookmallinfo(item.storeId)"
              v-for="(item, index) in mallList"
              :key="index"
            >
              <div class="activity-item-img" style="height: 250px">
                <img
                  :src="
                    item.storeImg.substring(4) == '/api'
                      ? item.storeImg
                      : '/api' + item.storeImg
                  "
                  alt=""
                  style="height: 250px"
                />
                <div class="activity-item-price">
                  ￥{{ item.arr[0].guidancePrice }}
                </div>
              </div>
              <div class="activity-item-info">
                <div class="activity-item-title">{{ item.storeTitle }}</div>
                <div class="activity-item-desc">销量：{{ item.storeXl }}</div>
                <!-- <div class="activity-item-time">数量：{{item.sotreNum}}</div> -->
                <!--<div class="activity-item-location" title="111111111">
                    111111111111111
                  </div> -->
              </div>
            </div>
          </div>
          <el-empty
            v-if="this.mallList.length == 0"
            description="暂无数据"
          ></el-empty>
        </div>
      </div>
      <!-- <div class="activity">
        <div class="activity-title flexBetween"> <div class="title">旅行相册</div> <div class="more" @click="goMoreAlbum()">更多</div>  </div>
        <div class="activity-list">
          <div class="activity-item" v-for="(album, index_album) in albumList" :key="index_album" @click="goDetailAlbum(album.activityId)">
            <div class="activity-item-img">
              <img :src="album.activityPicture" alt="">
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="activity">
        <div class="activity-title flexBetween"> <div class="title">游记攻略</div> <div class="more" @click="goyouji()">更多</div>  </div>
        <div class="activity-list">
          <div class="gonglueListitem" v-for="(gonglue, index_gonglue) in travelogueGuideList" :key="index_gonglue" @click="gosyouji(gonglue.strategyId)">
            <div class="gonglueListitem-img">
              <img :src="gonglue.strategyCover" alt="" />
            </div>
            <div class="gonglueListitem-info">
              <div class="gonglueListitem-title">{{gonglue.strategyTitle}}</div>
              <div class="gonglueListitem-content">
                {{gonglue.contentSummary}}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!--      <div class="activity">-->
      <!--        <div class="activity-title flexBetween"> <div class="title">精彩话题</div><div class="more" @click="goluntan()">更多</div>  </div>-->
      <!--        <div class="activity-list">-->
      <!--          <div style="text-align: center;color: #999;padding-bottom:30px">暂无数据</div>-->
      <!--          <div class="gonglueListitem" v-for="(huati, index_huati) in huatiList" :key="index_huati" @click="goshuati(huati.exchangeId)">-->
      <!--          <div class="gonglueListitem-img">-->
      <!--            <img :src="imgUrls" alt="" />-->
      <!--          </div>-->
      <!--          <div class="gonglueListitem-info">-->
      <!--            <div class="gonglueListitem-title">{{huati.exchangeModule}}</div>-->
      <!--            <div class="gonglueListitem-content">-->
      <!--              {{huati.exchangeTitle}}-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- <div class="activity">
        <div class="activity-title flexBetween"> <div class="title">交流论坛</div> <div class="more" @click="goluntan()">更多</div>  </div>
        <div class="activity-list">
          <div class="gonglueListitem" @click="gosluntan()">
            <div class="gonglueListitem-img">
              <img :src="img" alt="" />
            </div>
            <div class="gonglueListitem-info">
              <div class="gonglueListitem-title">论坛</div>
              <div class="gonglueListitem-content">
                交流论坛
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- <recommend></recommend> -->
    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// 页面逻辑
import Header from "@/components/header.vue"; // 引入头部组件
import Navs from "@/components/nav.vue"; // 引入头部组件
import mobileHeader from "@/components/mobileHeader.vue"; // 引入头部组件
import Footer from "@/components/Footer.vue";
let img = require("@/static/img/14e4468eb32.jpg"); // 图片
let imgUrls = require("@/static/img/poster.jpg"); // 图片路径
import recommend from "@/components/recommend.vue";
import api from "@/common/request/request";
import apiUser from "@/common/request/user";
import imgUrl from "@/common/imgUrl";
import router from "@/router";
import mobileRecommend from "@/components/mobileRecommend.vue";
let poster = require("@/static/img/poster.jpg"); // 引入海报图片
export default {
  // 页面逻辑
  components: {
    Header,
    mobileHeader,
    Navs, // 注册头部组件
    Footer, // 注册底部组件
    recommend, // 注册推荐活动组件
    mobileRecommend,
  },
  data() {
    return {
      applicationList: [],
      voteList: [],
      activeName: "first",
      activeName2: "first",
      activeName3: "first",
      activeName4: "first",
      activeName5: "first",
      activityList: [],
      noticeList: [],
      actList: [],
      districtList: [],
      playerList: [],
      mediaList: [],
      companyList: [],
      mallList: [],
    };
  },
  created() {
    this.getActivityList();
    this.getNoticeList(); // 获取公告列表数据
    this.getActList(); // 获取活动列表数据
    this.getDistrictList(); // 获取选区风采列表数据
    this.getPlayerList(); // 获取选手展示列表数据
    this.getMediaList(); // 获取媒体报道列表数据
    this.getCompanyList(); // 获取企业展示列表数据
    this.getApplicationList();
    this.geVoteList(); // 获取活动列表数据
    this.getStoreList(); // 获取商城列表数据
  },
  methods: {
    lookApplicationInfo(id) {
      this.$router.push({
        path: "/application/info",
        query: {
          id: id,
        },
      });
    },
    getStoreList() {
      api.getStoreList().then((res) => {
        console.log(res);
        this.mallList = res.data.data.splice(0, 4);
      });
    },
    lookmallinfo(id) {
      this.$router.push({
        path: "/mall/info",
        query: {
          id: id,
        },
      });
    },
    lookMoreMall() {
      this.$router.push("/mall");
    },
    getActivityList() {
      api.getActivityList({ articleType: "1" }).then((res) => {
        console.log(res);
        this.activityList = res.data.rows.splice(0, 5);
        this.activityList.forEach((item) => {
          item.createTime = item.createTime.substring(0, 10);
        });
      });
    },
    lookPlayerinfo(item) {
      this.$router.push({
        path: "/players/info",
        query: {
          id: item.personId,
        },
      });
    },
    lookcominfo(item) {
      this.$router.push({
        path: "/district/info",
        query: {
          id: item.articleId,
        },
      });
    },
    lookDongtaiinfo(item) {
      if (item.articleType == 1) {
        this.$router.push({
          path: "/lastest/info",
          query: {
            id: item.articleId,
          },
        });
      }
      if (item.articleType == 7) {
        this.$router.push({
          path: "/notice/info",
          query: {
            id: item.articleId,
          },
        });
      }
      if (item.articleType == 9) {
        this.$router.push({
          path: "/activity/info",
          query: {
            id: item.articleId,
          },
        });
      }
      if (item.articleType == 3) {
        this.$router.push({
          path: "/district/info",
          query: {
            id: item.articleId,
          },
        });
      }
      if (item.articleType == 5) {
        this.$router.push({
          path: "/players/info",
          query: {
            id: item.articleId,
          },
        });
      }
      if (item.articleType == 4) {
        this.$router.push({
          path: "/media/info",
          query: {
            id: item.articleId,
          },
        });
      }
      if (item.articleType == 2) {
        this.$router.push({
          path: "/company/info",
          query: {
            id: item.articleId,
          },
        });
      }
    },
    lookdongtai() {
      this.$router.push("/lastest");
    },
    lookNotice() {
      this.$router.push("/notice");
    },
    lookAct() {
      this.$router.push("/activity");
    },
    lookplayer() {
      if (this.activeName4 == "first") {
        this.$router.push("/district");
      }
      if (this.activeName4 == "second") {
        this.$router.push("/players");
      }
    },
    lookmedia() {
      if (this.activeName5 == "first") {
        this.$router.push("/company");
      }
      if (this.activeName5 == "second") {
        this.$router.push("/company");
      }
    },
    getNoticeList() {
      api.getActivityList({ articleType: "7" }).then((res) => {
        console.log(res);
        this.noticeList = res.data.rows.splice(0, 5);
        this.noticeList.forEach((item) => {
          item.createTime = item.createTime.substring(0, 10);
        });
      });
    },
    getActList() {
      api.getActivityList({ articleType: "9" }).then((res) => {
        console.log(res);
        this.actList = res.data.rows.splice(0, 10);
        this.actList.forEach((item) => {
          item.createTime = item.createTime.substring(0, 10);
        });
      });
    },
    getDistrictList() {
      // api.getActivityList({articleType:'3'}).then((res) => {
      //   console.log(res);
      //   this.districtList = res.data.rows.splice(0,5);
      //   this.districtList.forEach((item) => {
      //     item.createTime = item.createTime.substring(0, 10);
      //   });
      // });
      api.getZsjyCompetition().then((res) => {
        console.log(res);
        this.districtList = res.data.rows.splice(0, 5);
        this.districtList.forEach((item) => {
          item.createTime = item.createTime.substring(0, 10);
        });
      });
    },
    getPlayerList() {
      // api.getActivityList({articleType:'5'}).then((res) => {
      //   console.log(res);
      //   this.playerList = res.data.rows.splice(0, 5);
      //   this.playerList.forEach((item) => {
      //     item.createTime = item.createTime.substring(0, 10);
      //   });
      // });
      api.getPersonList().then((res) => {
        console.log(res);
        this.playerList = res.data.rows.splice(0, 5);
        // this.playerList.forEach((item) => {
        //   item.createTime = item.createTime.substring(0, 10);
        // });
      });
    },
    getMediaList() {
      // api.getActivityList({articleType:'4'}).then((res) => {
      //   console.log(res);
      //   this.mediaList = res.data.rows.splice(0, 5);
      //   this.mediaList.forEach((item) => {
      //     item.createTime = item.createTime.substring(0, 10);
      //   });
      // });
      // getActivityList() {
      // api.getZsjyCompetition().then((res) => {
      //   console.log(res);
      //   this.mediaList = res.data.rows.splice(0, 5);
      //   this.mediaList.forEach((item) => {
      //     item.createTime = item.createTime.substring(0, 10);
      //   });
      // });
      // },
    },
    getCompanyList() {
      api.getActivityList({ articleType: "2" }).then((res) => {
        console.log(res);
        this.companyList = res.data.rows.splice(0, 4);
        this.companyList.forEach((item) => {
          item.createTime = item.createTime.substring(0, 10);
        });
      });
    },
    getApplicationList() {
      api.getApplicationListAll({ classifyId: this.classifyId }).then((res) => {
        console.log(res);
        this.applicationList = res.data.data.splice(0, 4);
        this.applicationList.forEach((item) => {
          item.activityImgs = item.activityImgs.split(",");
        });
        console.log("111", this.applicationList);
      });
    },
    //   获取活动列表数据
    geVoteList() {
      // api
      //   .getVoteListAll({ classifyId: this.classifyId, voteTitle: this.input2 })
      //   .then((res) => {
      //     console.log(res);
      //     this.voteList = res.data.data.splice(0, 4);
      //     this.voteList.forEach((item) => {
      //       item.voteImgs = item.voteImgs.split(",");
      //     });
      //     console.log("111", this.voteList);
      //   });
      api.getApplicationListAll().then((res) => {
        console.log(res);
        this.voteList = res.data.data.splice(0, 4);
        this.voteList.forEach((item) => {
          item.activityImgs = item.activityImgs.split(",");
        });
        console.log("111", this.voteList);
      });

    },
    lookMoreAct() {
      this.$router.push("/application");
    },
    lookMoreVote() {
      this.$router.push("/vote");
    },
    lookInfo(id) {
      // this.$router.push({ path: '/application/info' })
      router.push({
        path: "/vote/info",
        query: {
          id: id,
        },
      });
    },
    lookInfoAct(id) {
      // this.$router.push({ path: '/application/info' })
      router.push({
        path: "/application/info",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style scoped>
/* PC端页面的样式 */
.bemImg {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.activity {
  background-color: #ffffff;
  padding: 14px 24px;
  margin-top: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}
.activity-title {
  font-size: 24px;
  color: #333;
  padding-bottom: 20px;
}
.activity-item {
  margin-bottom: 10px;
  position: relative;
  width: 240px;
  background: #f3f3f3;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.activity-item-video {
  margin-bottom: 10px;
  position: relative;
  width: 500px;
  background: #f3f3f3;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
}
.gonglueListitem {
  margin-bottom: 10px;
  position: relative;
  width: 500px;
  display: inline-flex;
  margin-right: 15px;
  cursor: pointer;
  color: #333 !important;
}
.gonglueListitem-info {
  margin-left: 10px;
  width: 100%;
  height: 120px;
}
.gonglueListitem-title {
  color: #f86532;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;

  margin-bottom: 5px;
}
.gonglueListitem-content {
  color: #333;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.gonglueListitem-img {
  width: 130px;
  height: 100px;
  background: #ccc;
  background-size: cover;
  flex-shrink: 0;
}
.gonglueListitem-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-item:nth-child(4n) {
  margin-right: 0;
}
.activity-item-img {
  width: 100%;
  height: 180px;
  position: relative;
}
.activity-item-img video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-item-title {
  width: 100%;
  height: 42px;
  color: #f86532;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.activity-item-info {
  padding: 10px;
}
.activity-item-desc {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-time {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-location {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.animation {
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
}
.activity-item-btn {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
  position: relative;

  width: 180px;
}
.activity-item-price {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #f86532;
  background: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
}
.more {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #999;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style lang="scss">
.home {
  .el-tabs__nav-wrap:after {
    z-index: 0;
  }
  .lookmore {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    font-size: 16px;
  }
  .container {
    width: 1080px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .hongdong {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 3px;
    .title {
      font-weight: bold;
      font-size: 20px;
      padding-top: 10px;
      width: 313px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .type {
      margin-top: 10px;
      font-size: 18px;
    }
    .time {
      display: flex;
      align-items: center;
      margin-top: 10px;
      > div {
        padding-left: 5px;
        font-size: 13px;
      }
    }
    .hongdonginfo {
      display: flex;
      justify-content: space-between;
      margin-top: 7px;
      > div:first-child {
        font-size: 14px;
        color: #ce191b;
      }
      > div:nth-child(2) {
        cursor: pointer;
        font-size: 14px;
        &:hover {
          color: #df3333;
        }
      }
    }
    .count {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      margin-top: 8px;
      padding-bottom: 10px;
      > div {
        padding-left: 5px;
        font-size: 13px;
      }
    }
  }
  .qitalist {
    margin-top: 26px;
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      align-items: center;
      cursor: pointer;
      &:hover {
        color: #df3333;
        background-image: url(../../assets//redarrow.png);
        background-repeat: no-repeat;
        background-position: 0 center;
        padding-left: 12px;
        > div {
          display: flex;
          align-items: center;
        }
      }
      &:hover > div > img {
        display: none;
      }
      > div:first-child {
        display: flex;
        align-items: center;
        div {
          font-size: 18px;
          margin-left: 5px;
          width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      > div:nth-child(2) {
        font-size: 14px;
      }
    }
  }
  .dangjianlist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    cursor: pointer;
    &:hover {
      color: #df3333;
      background-image: url(../../assets//redarrow.png);
      background-repeat: no-repeat;
      background-position: 0 center;
      padding-left: 12px;
      > div {
        display: flex;
        align-items: center;
      }
    }
    &:hover > div > img {
      display: none;
    }
    > div:first-child {
      display: flex;
      align-items: center;
      div {
        font-size: 18px;
        margin-left: 5px;
        width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    > div:nth-child(2) {
      font-size: 14px;
    }
  }
  .firstdangjian {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 18px;
    margin-bottom: 26px;
    img {
      margin-top: 12px;
    }
    div {
      > p:first-child {
        margin-left: 10px;
        font-size: 20px;
        width: 337px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 19px;
      }
      > p:nth-child(2) {
        margin-left: 10px;
        margin-top: 16px;
        font-size: 16px;
        width: 337px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      > div {
        cursor: pointer;
        font-size: 16px;
        margin-top: 15px;
        margin-left: 10px;
        &:hover {
          color: #df3333;
        }
      }
    }
  }
  .dongtailist {
    margin-top: 43px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 35px;
      cursor: pointer;
      > div:first-child {
        display: flex;
        align-items: center;
        > div {
          font-size: 20px;
          padding-left: 5px;
          width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &:hover {
        color: #df3333;
        background-image: url(../../assets//redarrow.png);
        background-repeat: no-repeat;
        background-position: 0 center;
        padding-left: 12px;
        > div {
          display: flex;
          align-items: center;
        }
      }
      &:hover > div > img {
        display: none;
      }
      > div:nth-child(2) {
        font-size: 14px;
      }
    }
  }
  // background-image: url(../assets/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  .el-tabs__item.is-active {
    color: #ad0c0c;
  }
  .el-tabs__item:hover {
    color: #ad0c0c;
  }
  .el-tabs__item {
    color: #878787;
    font-size: 26px;
    font-weight: 700;
  }
  .el-tabs__active-bar {
    background-color: #ad0c0c;
  }
}
</style>
