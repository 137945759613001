<!-- src/pc/PCPage.vue -->
<template>
  <div>
    <Header v-on:MenuList="handleMenuList"></Header>
    <!-- PC端页面内容 -->
    <div class="about">
      <div class="container2" v-if="activityInfo">
        <div>
          <div>
            <div class="bread">
              <span style="line-height: 16px">您当前的位置:</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"
                  >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item :to="{ path: '/players' }"
                  >选手展示</el-breadcrumb-item
                >
                <el-breadcrumb-item
                  ><div
                    style="
                      max-width: 700px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      font-size: 16px;
                      line-height: 16px;
                    "
                  >
                    {{ this.activityInfo.personName }}
                  </div></el-breadcrumb-item
                >
              </el-breadcrumb>
            </div>
          </div>
        </div>
        <p class="infotitle">
          {{ this.activityInfo.personName }}
        </p>
        <div class="infofeilei">
          <!-- <div>文章类别：选手展示</div> -->
          <!-- <div>发布时间：{{ this.activityInfo.createTime }}</div> -->
          <!-- <div>阅读人数：1111111111111</div> -->
        </div>
        <div
          class="infocontent"
          style="text-indent: 2em"
          v-html="this.activityInfo.playerContent"
        ></div>
        <!-- <div class="infocontent" style="text-indent: 2em;">11111111111</div> -->
      </div>
      <el-empty
        style="min-height: calc(100vh - 290px)"
        v-else
        description="暂无数据"
      ></el-empty>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// 页面逻辑
import Header from "@/components/header.vue"; // 引入头部组件
import breadcrumbs from "@/components/Breadcrumbs.vue"; // 引入面包屑组件
import LatestActivities from "@/components/LatestActivities.vue"; // 引入最新活动组件
import Footer from "@/components/Footer.vue";
import mobileHeader from "@/components/mobileHeader.vue"; // 引入头部组件
import navs from "@/components/nav.vue"; // 引入导航组件
let img = require("@/static/img/banner.jpg"); // 引入轮播图图片
import api from "@/common/request/request";
import apiuser from "@/common/request/user";
import imgUrl from "@/common/imgUrl";
import router from "@/router";
import { toRaw } from "@vue/reactivity";

export default {
  // 页面逻辑
  components: {
    Header, // 注册头部组件
    breadcrumbs, // 注册面包屑组件
    LatestActivities, // 注册最新活动组件
    Footer, // 注册底部组件
    mobileHeader, // 注册移动端头部组件
    navs, // 注册导航组件
  },
  data() {
    return {
      activityInfo: null,
    };
  },

  watch: {
    // $route(to, from) {
    //     if (this.$route.query.status) {
    //         this.status = this.$route.query.status
    //         this.getActivityLists() // 获取推荐活动数据
    //     }
    // }
  },
  created() {
    let id = this.$route.query.id;
    this.getActivityInfo(id); // 获取活动详情
  },
  mounted() {
    // 页面挂载
  },
  methods: {
    getActivityInfo(id) {
      api.getPersonInfo(id).then((res) => {
          if (res.data.code === 200) {
            this.activityInfo = res.data.data;
            console.log(this.playerInfo);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-size: 16px !important;
  &:hover {
    color: #b52024 !important;
  }
}
</style>
<style lang="scss" scoped>
.about {
  // background-image: url(../assets/bg.png);
  .container2 {
    min-height: calc(100vh - 290px);
    overflow: auto;
    width: 1080px;
    margin: 0 auto;
    background-color: #fff;
  }
  .bread {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 16px;
    margin-top: 56px;
    margin-left: 38px;
    > span {
      padding-right: 10px;
    }
  }
  .infotitle {
    text-align: center;
    margin-top: 68px;
    font-size: 32px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 34px;
    margin-left: 44px;
    margin-right: 44px;
  }
  .infofeilei {
    display: flex;
    justify-content: space-around;
    margin-top: 18px;
    font-size: 16px;
    color: #858585;
  }
  .infocontent {
    margin-top: 44px;
    margin-left: 48px;
    margin-bottom: 44px;
    margin-right: 44px;
  }
}
</style>
