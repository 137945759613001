<template>
  <div class="footer">
    <!-- <br> -->
    <div style="display: flex; align-items: center; justify-content: center;padding-top: 7px;">
      <p class="mr">版权所有:©中视佳音网</p>
      <p class="mr">联系我们：13718128521</p>
      <p>电子邮箱：www68686@163.com</p>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <p class="mr">法律顾问：北京大成（石家庄）律师事务所</p>
      <p class="mr">
        技术支持：<a href="https://qianxiaosoft.com/" target="_blank"
          >石家庄千枭科技有限公司</a
        >
      </p>
      <!-- <p class="mr">公安备案号:</p> -->
    </div>
    <!-- <div style="display: flex; align-items: center; justify-content: center"> -->
      <!-- <p class="mr">互联网出版许可证：</p> -->
      <!-- <p class="mr">广播电视节目制作许可证：</p> -->
      <!-- <p class="mr">友情链接</p> -->
      
    <!-- </div> -->
    <div style="display: flex; align-items: center; justify-content: center">
      <div>
        <p>
        工信部备案号：<a href="http://www.beian.miit.gov.cn/" target="_blank"
          >冀ICP备2023032288号-2</a
        >
      </p>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <div>
        <p>
          版权声明：本站所有内容，除特别注明外，均为中视佳音网原创，未经授权，禁止转载、摘编、复制或建立镜像。
        </p>
      </div>
    </div>
    <!-- <br> -->
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Footer",
});
</script>
<style scoped>
.footer {
  width: 100%;
  /* height: 80px; */
  background-color: #333;
  height: 90px;
  /* padding-top: 5px; */
  /* overflow: hidden; */
  /* padding: 24px 0; */
  /* padding-top: 20px; */
}
.footer p {
  text-align: center;
  color: #fff;
  line-height: 16px;
  font-size: 13px;
}
.footer p a {
  color: #fff;
  text-decoration: none;
}
.mr {
  margin-right: 20px;
}
</style>
