<!-- src/pc/PCPage.vue -->
<template>
  <div>
    <Header></Header>
    <!-- PC端页面内容 -->
    <div class="main breadcrumb-container">
      <!--  引入面包屑组件-->
      <div class="bread">
        <span>您当前的位置:</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>个人中心</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="content main">
      <div class="content-container">
        <div class="content-left">
          <div class="top-container">
            <div class="top-title">个人中心</div>
            <div class="top-content">
              <div class="top-content-right">
                <div class="top-content-right-content active">我的主页</div>
                <div
                  class="top-content-right-content"
                  @click="navToPage('/user/info')"
                >
                  我的资料
                </div>
                <div
                  class="top-content-right-content"
                  @click="navToPage('/user/applicateList')"
                >
                  我的报名
                </div>
                <div class="top-content-right-content" @click="navToPage('/user/voteList')">我的投票</div>
                <div class="top-content-right-content" @click="navToPage('/user/orderList')">我的订单</div>
                <!-- <div
                  class="top-content-right-content"
                  @click="navToPage('/user/integral')"
                >
                  我的积分
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="mint-container">
            <div class="userubox">
              <div class="userubox-item">
                <div class="img-box">
                  <img
                    v-if="userInfo.userPortrait"
                    :src="'/api' + userInfo.userPortrait"
                    alt
                  />
                </div>
                <div class="userubox-item-content">
                  <div class="userubox-item-content-title">
                    昵称：{{userInfo.userNickname}}
                  </div>
                  <div class="userubox-item-content-desc">
                    性别：{{ userInfo.userGender == 0? "男" : "女" }}
                  </div>
                  <div class="userubox-item-content-btn">来自: 未知</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <recommend></recommend> -->
    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";

// 页面逻辑
import Footer from "@/components/Footer.vue"; // 引入头部组件
import Header from "@/components/header.vue"; // 引入头部组件
import recommend from "@/components/recommend.vue"; // 引入头部组件
import breadcrumbs from "@/components/Breadcrumbs.vue"; // 引入面包屑组件
let img = require("@/static/img/qrcode.jpg"); // 引入轮播图图片
import router from "@/router";
import api from "@/common/request/request";
import imgUrl from "@/common/imgUrl";
import LatestActivities from "@/components/LatestActivities.vue";
import navs from "@/components/nav";
export default {
  // 页面逻辑
  components: {
    LatestActivities,
    Header, // 注册头部组件
    breadcrumbs, // 注册面包屑组件
    Footer,
    recommend,
    navs,
  },
  data() {
    return {
      userInfo: {},
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("zsjyuserInfo"));
    console.log("useriNFO", this.userInfo);
    // 页面创建完成
  },
  watch: {},
  methods: {
    navToPage(path) {
      router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* PC端页面的样式 */
.bread {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  padding-top: 5px;
  font-size: 16px;
  margin-top: 26px;
  margin-left: 0px;
  > span {
    padding-right: 10px;
  }
}
.breadcrumb-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  .el-breadcrumb {
    font-size: 16px;
  }
}

.SeBox {
  margin-left: 20px;
  margin-top: 10px;
}

.SeBox button {
  width: 60px;
  height: 30px;
  background-color: #409eff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.content-left {
  width: 228px;
  background-color: #fff;
  flex-shrink: 0;
  padding: 15px;
  box-sizing: border-box;
}

.content-right {
  overflow: hidden;
  padding: 30px;
  width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
}

.content-container {
  display: flex;
  position: relative;
  min-height: calc(100vh - 390px);
}

.content {
  margin: 20px auto;
  box-sizing: border-box;
}

.activity-condition {
  display: flex;

  align-items: center;
  margin-bottom: 20px;
}

.condition-title {
  font-size: 16px;
  color: #444;
}

.condition-content {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.top-container {
  width: 100%;
  border-bottom: 1px solid #eee;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}

.top-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 15px;
  color: #444;
  border-bottom: 1px solid #eee;
}

.top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.top-content-right-content {
  display: block;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  padding: 0 20px;
}

.active {
  color: #f86532;
}

.top-content-content {
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.top-content-content img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.top-content-content-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
}

.top-content-left {
  width: 30%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.activity-title {
}

.activity-item {
  margin-bottom: 20px;
  position: relative;
  width: 250px;
  height: 300px;
  background: #f3f3f3;
  display: inline-block;
  margin-right: 21px;
}

.activity-item:nth-child(3n) {
  margin-right: 0;
}

.activity-item-img {
  width: 100%;
  height: 180px;
  position: relative;
}

.activity-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.activity-item-title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #353535;
  font-size: 14px;
  font-weight: bold;
}

.activity-item-info {
  padding: 10px;
}

.activity-item-desc {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
}

.activity-item-time {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
}

.activity-item-location {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
}

.activity-item-btn {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}

.activity-item-price {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
}

.mint-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.userubox {
  margin-top: 10px;
}

.userubox-item {
  display: flex;
  padding: 20px;
  background: #f3f3f3;
  border-radius: 4px;
  margin-bottom: 10px;
}

.dialog-content {
  line-height: 24px;
}

.img-box {
  width: 100px;
  height: 100px;
}

.img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.userubox-item-content {
  margin-left: 20px;
  margin-top: 15px;
}

.userubox-item-content-title {
  font-size: 16px;
  color: #333;
}

.userubox-item-content-desc {
  font-size: 14px;
  color: #666;
  margin-top: 6px;
}
.userubox-item-content-btn {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}
</style>
