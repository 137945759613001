import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import ElementPlus from 'element-plus'
import vRegion from 'v-region'
// import html2canvas from 'html2canvas'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { Cell, Uploader, Picker, ActionBar, SubmitBar, ActionBarIcon, ActionBarButton, Button, Lazyload, Swipe, Calendar, SwipeItem, Icon, ImagePreview, Empty, Notify, NavBar, Tab, Field, CellGroup, Tabs, Dialog, Search, RadioGroup, Radio, CheckboxGroup, Checkbox, Switch, Popover, DropdownMenu, DropdownItem, Loading, Form, List, Tabbar, TabbarItem, Sticky, Popup } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
import 'element-plus/dist/index.css'

const app = createApp(App)
// app.use(html2canvas)
app.use(Cell);
app.use(ActionBar, Picker);
app.use(Uploader);
app.use(ActionBarIcon);
app.use(ActionBarButton);
app.use(router)
app.use(ElementPlus)
app.use(Button)
app.use(Cell);
app.use(Lazyload)
app.use(Swipe)
app.use(SwipeItem)
app.use(ImagePreview)
app.use(Calendar)
app.use(Empty)
app.use(Notify)
app.use(NavBar)
app.use(Tab)
app.use(Field)
app.use(CellGroup)
app.use(Tabs)
app.use(Dialog)
app.use(Search)
app.use(Radio)
app.use(RadioGroup)

app.use(Checkbox)
app.use(CheckboxGroup)
app.use(SubmitBar)

app.use(Switch)
app.use(Popover)
app.use(DropdownMenu)
app.use(DropdownItem)
app.use(Loading)
app.use(Form)
app.use(List)
app.use(Tabbar)
app.use(TabbarItem)
app.use(Sticky)
app.use(Popup)
app.use(Icon)
app.use(vRegion)
app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}