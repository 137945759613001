<template>
  <div class="nav">
    <van-nav-bar :title="$route.name" fixed="true" placeholder="true">
      <template #left v-if="isshow">
        <van-icon name="user-circle-o" @click="navTo('/user')" size="24" color="#444" />
      </template>
      <template #left v-if="!isshow">
        <van-icon name="arrow-left" size="24" color="#444" @click="this.$router.go(-1)" />
      </template>
      <template #right>
        <!-- <van-icon
          name="cart-o"
          size="24"
          color="#444"
          @click="showToast('success')"
        /> -->
        <!-- <div class="span"></div> -->
        <van-icon name="apps-o" size="24" color="#444" @click="popupShow" />
      </template>
    </van-nav-bar>
    <van-popup
      v-model:show="showPopup"
      :style="{ height: '100%', width: '60%' }"
      position="right"
    >
      <div class="popup-content">
        <div class="popup-title">青年社交</div>
        <mobileHeader />
      </div>
    </van-popup>
  </div>
</template>
<script>
// import { defineComponent } from "vue";
import mobileHeader from "@/components/mobileHeader.vue"; // 引入头部组件
import router from "@/router";
export default {
  name: "nav",
  data() {
    return {
        title: "青年社交", // 标题
      showPopup: false, // 弹窗显示状态
      isshow: false, // 弹窗显示状态
    };
  },
  components: {
    mobileHeader,
  },
  watch: {
    $route: function (to, from) {
        // console.log(to);
        this.showPopup = false;
    //   if (to.path.split("/")[1] === "") {
    //     this.isshow = true;

    //   } else {
    //     this.isshow = false;
    //   }
    },
  },
  mounted() {
    console.log(this.$route);
    if (this.$route.path.split("/")[1] === "") {
      this.isshow = true;
    }
  },
  created() {
    // console.log(this.MenuList)
    // for(let i=0;i<this.MenuList.length;i++) {
    //   if (this.MenuList[i].link.split('/')[1] === this.$route.path.split('/')[1]) {
    //     this.currentIndex = i
    //     break
    //   }
    // }
  },
  methods: {
    popupShow() {
      this.showPopup = true;
      console.log("弹窗显示");
    },
    navTo(url, subMenu) {
      if (!subMenu) {
        router.push(url);
      }
    },
    subNavTo(url) {
      router.push(url);
    },
  },
};
</script>

<style scoped>
.popup-content {
  height: 100%;
  overflow: auto;
}
.popup-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  padding-top: 20px;
  text-align: center;
}
</style>
