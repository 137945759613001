<script>
import {defineComponent} from 'vue'
import Menu from "./menu"
export default defineComponent({
  name: "Breadcrumbs",
  props:{
    Subtitle:String,
  },
  data() {
    return {
      // data
      MenuList:Menu,
      currentIndex:null,
      fIndex:null,
    }
  },
  watch: {

  },
  created() {
    for(let i=0;i<this.MenuList.length;i++) {

      if (this.MenuList[i].link.split('/')[1] === this.$route.path.split('/')[1]) {
        this.currentIndex = i
        break
      }
    }
  },
  methods: {
    toIndex(index) {
      console.log(index)

      if(this.$router.currentRoute.value.path ===  '/contact/photo/photoDetail' || this.$router.currentRoute.value.path ===  '/contact/photo/albums'){
        this.$router.push('/contact/photo/photoList')
        return
      }
      if(this.$router.currentRoute.value.path ===  '/contact/video/videoDetail'){
        this.$router.push('/contact/video/videoList')
        return
      }
      if(this.$router.currentRoute.value.path ===  '/contact/video/videoList' || this.$router.currentRoute.value.path ===  '/contact/photo/photoList'){
        return
      }
      this.$router.push(this.MenuList[index].link)
    }
  },
})

</script>

<template>
  <div class="breadcrumbs">
    <router-link  to="/">首页</router-link>
    <span v-if="currentIndex || fIndex">/</span>
    <a style="cursor: pointer;" v-if="currentIndex || fIndex">
      <span v-if="MenuList[currentIndex].name == '旅游'" >
        活动
      </span>
      <span v-else @click="toIndex(currentIndex)">
         {{ MenuList[currentIndex].name }}
      </span>
    </a>
    <span v-if="Subtitle">/</span>
    <div><a >{{ Subtitle }}</a></div>

  </div>
</template>

<style scoped>
  .breadcrumbs {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
  }
  .breadcrumbs a {
    color: #333;
    text-decoration: none;
    font-size: 16px;
  }
 .breadcrumbs span {
    margin: 0 10px;
  }
</style>
