<script>
import { defineComponent, createApp } from "vue";
import Menu from "./menu";
import router from "@/router";
import api from "@/common/request/user";
import { Icon } from "vant";

const app = createApp();
app.use(Icon);

export default defineComponent({
  name: "mobileHeader",
  data() {
    return {
      MenuList: Menu, // 菜单列表
      currentPath: "", // 当前路由路径
    };
  },
  mounted() {
    // this.currentPath = router.currentRoute.path;
    this.dict();
  },
  methods: {
    dict() {
      api.getDictData("event_status").then((res) => {
        console.log(res);
        var arr = [];
        var brr = [];
        for (var i = 0; i < res.data.data.length; i++) {
          if (JSON.parse(res.data.data[i].dictValue) <= 5) {
            console.log(res.data.data[i]);
            arr.push({
              name: res.data.data[i].dictLabel,
              link: "/activities/index.js?status=" + res.data.data[i].dictValue,
              status: res.data.data[i].dictValue,
            });
          }
          if (JSON.parse(res.data.data[i].dictValue) > 5) {
            console.log(res.data.data[i]);
            brr.push({
              name: res.data.data[i].dictLabel,
              link: "/activities/index.js?status=" + res.data.data[i].dictValue,
              status: res.data.data[i].dictValue,
            });
          }
        }
        this.MenuList[2].subMenu = arr;
        this.MenuList[1].subMenu = brr;
        this.$emit("MenuList", this.MenuList);
      });
    },
    navTo(url, subMenu) {
      if (!subMenu) {
        router.push(url)
      }
    },
    subNavTo(path) {
      this.currentPath = path;
      router.push(path);
    },
  },
});
</script>

<template>
  <div class="menu">
    <div class="menu-item" v-for="(item, index) in MenuList" :key="index">

      <div class="menu-item-text" @click="navTo(item.link,item.subMenu)" v-if="!item.false">
        {{ item.name }}
        <van-icon v-if="!item.subMenu" name="arrow" style="float: right" />
      </div>
      <div class="sub-menu" v-if="item.subMenu" >
        <div class="sub-menu-item" v-for="items in item.subMenu">
          <div class="sub-menu-item-text" @click="subNavTo(items.link)">
            {{ items.name }} <van-icon name="arrow" style="float: right" />
          </div>
        </div>
      </div>
    </div>

    <div class="menu-item">
      <div class="menu-item-text" @click="navTo('/user')">个人中心</div>
    </div>
  </div>
</template>

<style scoped>
.menu {
  margin-bottom: 10px;
}
.menu-item {
  /* margin-bottom: 10px; */
  line-height: 3;
  /* border-bottom: 1px solid #e4e4e4; */
}
.menu-item-text {
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  border-bottom: 1px solid #e4e4e4;
}
.sub-menu {
  /* margin-bottom: 10px; */
}
.sub-menu-item {
  /* margin-top: 10px; */
  border-bottom: 1px solid #e4e4e4;
}
.sub-menu-item-text {
  font-size: 15px;
  color: #999;
  padding: 0 5% 0% 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
