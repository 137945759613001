<!-- src/pc/PCPage.vue -->
<template>
  <div>
    <Header v-on:MenuList="handleMenuList"></Header>
    <!-- PC端页面内容 -->
    <div class="about" v-if="playerInfo">
      <div class="container2">
        <div>
          <div>
            <div class="bread">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"
                  >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item :to="{ path: '/vote' }"
                  >网上投票</el-breadcrumb-item
                >
                <el-breadcrumb-item>{{
                  playerInfo.personName
                }}</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="content main" style="margin: 20px 0 20px 0px">
              <div
                class="active-title"
                style="margin-left: 10px; margin-top: 20px"
              >
                <!-- {{  playerInfo.playerName }} -->
              </div>
              <div class="active-content">
                <div class="active-img">
                  <img
                    style="width: 300px; height: 300px; border-radius: 50%"
                    alt
                    :src="
                      playerInfo.playerImg.substring(0, 4) == '/api'
                        ? playerInfo.playerImg
                        : '/api' + playerInfo.playerImg
                    "
                  />
                </div>
                <div class="active-info" style="margin-left: 10px">
                  <div class="active-price">
                    <div class="active-price-box">
                      选手姓名:{{ playerInfo.personName }}
                    </div>
                    <div class="active-price-peo">
                      <span>已投票{{ playerInfo.number2 }}</span>
                    </div>
                  </div>
                  <!-- <div class="active-time">
                    选手姓名：{{ playerInfo.playerName }}
                  </div> -->
                  <div class="active-time">
                    选手编号：{{ playerInfo.playerSerial }}
                  </div>
                  <!-- <div class="active-time" style="width: 700px;">
                    选手介绍：{{ playerInfo.playerBrief }}
                  </div> -->
                  <div class="active-time">备注：{{ playerInfo.remark }}</div>
                  <!-- <div class="active-time">
                    已投票：{{ playerInfo.number }}
                  </div> -->
                  <!--<el-divider border-style="dashed" />

                  <div class="active-address-notop">
                    投票间隔：{{ voteInfo.voteJg }}
                  </div>
                  <div class="active-address">
                    投票次数：{{ voteInfo.voteNumber }}
                  </div> -->
                  <div class="active-address">
                    <el-button
                      type="primary"
                      size="large"
                      @click="voteplayer"
                      v-if="voteStatus == '1'"
                      >我要投票</el-button
                    >
                    <el-button
                      type="info"
                      size="large"
                      @click="voteplayer"
                      v-if="voteStatus == '0'"
                      disabled
                      >投票未开始</el-button
                    >
                    <el-button
                      type="info"
                      size="large"
                      @click="voteplayer"
                      v-if="voteStatus == '2'"
                      disabled
                      >投票已结束</el-button
                    >
                    <!-- v-if="activityDetail.eventHdstatus == '2'" -->
                    <!-- <el-button
                          type="primary"
                          size="large"
                          disabled
                          v-if="activityDetail.eventHdstatus == '1'"
                          >未开始报名</el-button
                        >
                        <el-button
                          type="info"
                          size="large"
                          disabled
                          v-if="activityDetail.eventHdstatus == '3'"
                          >已截止报名</el-button
                        > -->
                  </div>
                </div>
              </div>
            </div>
            <!-- 左侧内容 -->
            <div class="contentView main">
              <div
                style="
                  padding-left: 10px;
                  margin-top: 20px;
                  margin-bottom: 20px;
                  font-size: 20px;
                  font-weight: 700;
                "
              >
                选手简介
              </div>
              <div
                v-html="playerInfo.playerContent"
                style="
                  text-indent: 2em;
                  line-height: 1.5em;
                  font-size: 16px;
                  color: #333;
                  padding: 0 10px;
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-empty
      style="min-height: calc(100vh - 290px)"
      v-else
      description="暂无数据"
    ></el-empty>
    <el-dialog
      v-model="dialogVisible"
      title="投票确认"
      width="500"
      :before-close="handleClose"
    >
      <div>您确定投票给该选手吗？</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirm()">确认</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="dialogVisible2"
      title="投票须知"
      width="800"
      :before-close="handleClose2"
    >
      <div style="white-space: pre-line">{{ noticeData }}</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取消</el-button>
          <el-button type="primary" @click="confirm2()">确认</el-button>
        </div>
      </template>
    </el-dialog>
    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// 页面逻辑
import Header from "@/components/header.vue"; // 引入头部组件
import breadcrumbs from "@/components/Breadcrumbs.vue"; // 引入面包屑组件
import LatestActivities from "@/components/LatestActivities.vue"; // 引入最新活动组件
import Footer from "@/components/Footer.vue";
import mobileHeader from "@/components/mobileHeader.vue"; // 引入头部组件
import navs from "@/components/nav.vue"; // 引入导航组件
let img = require("@/static/img/banner.jpg"); // 引入轮播图图片
import api from "@/common/request/request";
import apiuser from "@/common/request/user";
import imgUrl from "@/common/imgUrl";
import router from "@/router";
import { toRaw } from "@vue/reactivity";

export default {
  // 页面逻辑
  components: {
    Header, // 注册头部组件
    breadcrumbs, // 注册面包屑组件
    LatestActivities, // 注册最新活动组件
    Footer, // 注册底部组件
    mobileHeader, // 注册移动端头部组件
    navs, // 注册导航组件
  },
  data() {
    return {
      playerInfo: null,
      dialogVisible: false, // 投票确认弹窗
      dialogVisible2: false, // 投票确认弹窗
      voteStatus: "",
      noticeData: "",
    };
  },

  watch: {
    // $route(to, from) {
    //     if (this.$route.query.status) {
    //         this.status = this.$route.query.status
    //         this.getActivityLists() // 获取推荐活动数据
    //     }
    // }
  },
  created() {
    let id = this.$route.query.id;
    this.getPlayerInfo(id);
    this.voteStatus = this.$route.query.activityStatus;
    console.log(this.voteStatus);
    this.getNoticeInfo(); // 获取公告信息
  },
  mounted() {
    // 页面挂载
  },
  methods: {
    getNoticeInfo() {
      console.log("111111111");
      api.getNoticeList("vote_config").then((res) => {
        console.log(res);
        this.noticeData = res.data.msg;
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleClose2() {
      this.dialogVisible2 = false;
    },
    getPlayerInfo(id) {
      api
        .getPersonInfo(id)
        .then((res) => {
          if (res.data.code === 200) {
            this.playerInfo = res.data.data;
            console.log(this.playerInfo);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    voteplayer() {
      if (
        !localStorage.getItem("zsjyuserInfo") ||
        localStorage.getItem("zsjyuserInfo") == null ||
        localStorage.getItem("zsjyuserInfo") === "null"
      ) {
        this.$message.error("请先登录");
        return false;
      }
      this.dialogVisible2 = true;
      //   this.playerInfo = item;
    },
    confirm2() {
      this.dialogVisible2 = false;
      this.dialogVisible = true;
    },
    confirm() {
      console.log(JSON.parse(localStorage.getItem("zsjyuserInfo")));
      // return false;
      var data = {
        voteId: Number(this.$route.query.activityId),
        playerId: this.playerInfo.personId,
        userName: JSON.parse(localStorage.getItem("zsjyuserInfo")).phone,
        logNumber: "1",
      };
      api.addVoteLog(data).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success("投票成功");
          // router.go(0)
          let id = this.$route.query.id;
          this.getPlayerInfo(id);
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.itembtn {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 14px;
  :nth-child(1) {
    background-color: #b52024;
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
  :nth-child(2) {
    background-color: #b52024;
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
    margin-left: 20px;
    cursor: pointer;
  }
}
.playerlist {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.playeritem {
  width: 300px;
  // border:1px solid #ccc;
  height: 100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 20px;
  margin-right: 15px;
  margin-bottom: 20px;
}
.playerimg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}
.playerinfo {
  padding-left: 20px;
  margin-top: 20px;
}
</style>
<style lang="scss" scoped>
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  &:hover {
    color: #b52024 !important;
  }
}
.about {
  .el-breadcrumb {
    font-size: 16px;
  }
  // background-image: url(../assets/bg.png);
  .container2 {
    min-height: calc(100vh - 290px);
    overflow: auto;
    width: 1080px;
    margin: 0 auto;
    background-color: #fff;
  }
  .bread {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 20px;
    margin-top: 36px;
    margin-left: 10px !important;
    > span {
      padding-right: 10px;
    }
  }
  .infotitle {
    text-align: center;
    margin-top: 68px;
    font-size: 32px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 34px;
    margin-left: 44px;
    margin-right: 44px;
  }
  .infofeilei {
    display: flex;
    justify-content: space-around;
    margin-top: 18px;
    font-size: 16px;
    color: #858585;
  }
  .infocontent {
    margin-top: 44px;
    margin-left: 48px;
    margin-bottom: 44px;
    margin-right: 44px;
  }
}
.huodong {
  //   margin-top: 51px;
  width: 300px;
  margin-right: 20px;
  display: flex;
  //   margin-left: 66px;
  .el-icon-search:before {
    color: #fff;
  }
}
.sanhuibutton {
  margin-left: 10px;
  background-color: #d71212 !important;
  color: #fff !important;
}
.sanhuibutton:hover {
  margin-left: 10px;
  background-color: #d71212;
  color: #fff;
}
.activity-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}
.activity-item {
  margin-bottom: 20px;
  position: relative;
  width: 250px;
  height: 300px;
  background: #f3f3f3;
  display: inline-block;
  margin-right: 21px;
  cursor: pointer;
}
.activity-item:nth-child(4n) {
  margin-right: 0;
}
.activity-item-img {
  width: 100%;
  height: 180px;
  position: relative;
}
.activity-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-item-title {
  width: 100%;
  height: 42px;
  color: #f86532;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.activity-item-info {
  padding: 10px;
}
.activity-item-desc {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-time {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-location {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-item-btn {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}
.activity-item-price {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #f86532;
  background: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
}
.el-check-tag {
  margin-bottom: 10px;
}
.activity-condition {
  display: flex;

  margin-bottom: 20px;
  margin-left: 36px;
  margin-top: 20px;
}

.condition-title {
  font-size: 16px;
  color: #444;
  flex-shrink: 0;
}
.condition-content {
  display: flex;

  margin-left: 20px;
  flex-wrap: wrap;
}
.content {
  box-sizing: border-box;
}
.active-title {
  font-size: 20px;
  font-weight: bold;
}
.active-content {
  display: flex;
}
.active-img {
  width: 320px;
  flex-shrink: 0;
}
.active-img-box {
  width: 100%;
  height: 320px;
}
.active-img-box img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}
.active-img-list {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
}
.active-img-item {
  width: 116px;
  height: 73px;
  border: 2px solid #ddd;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
}
.active-img-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-info {
  margin-left: 20px;
  width: 100%;
}
.active-time {
  padding: 10px 15px 0;
  font-size: 16px;
  color: #666;
}
.active-address {
  padding: 10px 15px 0;
  font-size: 16px;
  color: #666;
}
.active-address-notop {
  padding: 0 15px 0;
  font-size: 16px;
  color: #666;
}
.active-content {
  padding: 10px 10px 0 !important;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}
.active-price {
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active-price-box {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.active-price-peo {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
}
.contentView {
  margin-top: 20px;
}
.contentViewBox {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}
.img {
  max-width: 100%;
}
.active1 {
  border: 2px solid #409eff;
}
.active-price-box span {
  color: #ff4949;
}
</style>
