// src/router.js

import { createRouter, createWebHistory } from "vue-router";

import PCIndex from "./view/pc/home.vue";

import PCActivity from "./view/pc/activity/index";
import PCActivityInfo from "./view/pc/activity/info";
import PcNotice from "./view/pc/notice/index";
import PcNoticeInfo from "./view/pc/notice/info";
import PcLastest from "./view/pc/lastest/index";
import PcLastestInfo from "./view/pc/lastest/info";
import PcDistrict from "./view/pc/district/index";
import PcDistrictInfo from "./view/pc/district/info";
import PcPlayers from "./view/pc/players/index";
import PcPlayersInfo from "./view/pc/players/info";
import PcMedia from "./view/pc/media/index";
import PcMediaInfo from "./view/pc/media/info";
import PcCompany from "./view/pc/company/index";
import PcCompanyInfo from "./view/pc/company/info";
import PCAppliaction from "./view/pc/application/index";
import PCAppliactionInfo from "./view/pc/application/info";
import PCAppliacte from "./view/pc/application/applicate";
import PCVote from "./view/pc/vote/index";
import PCVoteInfo from "./view/pc/vote/info";
import PCVotePlayerInfo from "./view/pc/vote/playerInfo";
import PCMall from "./view/pc/mall/index";
import PCMallInfo from "./view/pc/mall/info";
import PCMallPay from "./view/pc/mall/pay";
import PcUserIndex from "./view/pc/user/index.vue";
import PCUserInfo from "./view/pc/user/info.vue";
import PCUserApplicate from "./view/pc/user/applicateList.vue";
import PCUserApplicateDetail from "./view/pc/user/applicateDetail.vue";
import PCUserVoteList from "./view/pc/user/voteList.vue";
import PCUserOrderList from "./view/pc/user/orderList.vue";
import PCUserVoteDetail from "./view/pc/user/voteDetail.vue";
import PCUserOrderDetail from "./view/pc/user/orderDetail.vue";
// 路由配置
if (window.innerWidth < 768) {
  location.href = "http://mini.zsjy.tv"; // location.href = "http://192.168.0.190:8080";
}
const routes = [
  {
    path: "/",
    name: "首页",
    component: PCIndex,
  },
  {
    path: "/activity",
    name: "活动介绍",
    component: PCActivity,
  },
  {
    path: "/activity/info",
    name: "活动介绍详情",
    component: PCActivityInfo,
  },
  {
    path: "/notice",
    name: "通知公告",
    component: PcNotice,
  },
  {
    path: "/notice/info",
    name: "通知公告详情",
    component: PcNoticeInfo,
  },
  {
    path: "/lastest",
    name: "最新动态",
    component: PcLastest,
  },
  {
    path: "/lastest/info",
    name: "最新动态详情",
    component: PcLastestInfo,
  },
  {
    path: "/district",
    name: "选区风采",
    component: PcDistrict,
  },
  {
    path: "/district/info",
    name: "选区风采详情",
    component: PcDistrictInfo,
  },
  {
    path: "/players",
    name: "选手展示",
    component: PcPlayers,
  },
  {
    path: "/players/info",
    name: "选手展示详情",
    component: PcPlayersInfo,
  },
  {
    path: "/media",
    name: "宣传报道",
    component: PcMedia,
  },
  {
    path: "/media/info",
    name: "宣传报道详情",
    component: PcMediaInfo,
  },
  {
    path: "/company",
    name: "企业展示",
    component: PcCompany,
  },
  {
    path: "/company/info",
    name: "企业展示详情",
    component: PcCompanyInfo,
  },
  {
    path: "/application",
    name: "网上报名",
    component: PCAppliaction,
  },
  {
    path: "/application/info",
    name: "网上报名详情",
    component: PCAppliactionInfo,
  },
  {
    path: "/application/applicate",
    name: "网上报名申请",
    component: PCAppliacte,
  },
  {
    path: "/vote",
    name: "网上投票",
    component: PCVote,
  },
  {
    path: "/vote/info",
    name: "网上投票详情",
    component: PCVoteInfo,
  },
  {
    path: "/vote/playerInfo",
    name: "网上投票选手详情",
    component: PCVotePlayerInfo,
  },
  {
    path: "/mall",
    name: "商城",
    component: PCMall,
  },
  {
    path: "/mall/info",
    name: "商品详情",
    component: PCMallInfo,
  },
  {
    path: "/mall/pay",
    name: "商品购买",
    component: PCMallPay,
  },
  {
    path: "/user",
    name: "个人中心",
    component: PcUserIndex,
  },
  {
    path: "/user/info",
    name: "我的信息",
    component: PCUserInfo,
  },
  {
    path: "/user/applicateList",
    name: "我的报名列表",
    component: PCUserApplicate,
  },
  {
    path: "/user/applicateDetail",
    name: "我的报名列表详情",
    component: PCUserApplicateDetail,
  },
  {
    path: "/user/voteList",
    name: "我的投票列表",
    component: PCUserVoteList,
  },
  {
    path: "/user/voteDetail",
    name: "我的投票列表详情",
    component: PCUserVoteDetail,
  },
  {
    path: "/user/orderList",
    name: "我的订单列表",
    component: PCUserOrderList,
  },
  {
    path: "/user/orderDetail",
    name: "我的订单列表详情",
    component: PCUserOrderDetail,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
