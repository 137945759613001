<template>
  <div class="recact">
    <div class="recacttitle">相关推荐</div>
    <div class="recactcontent">
      <div class="recactitem" v-for="(item,index) in activities" :key="index"  @mouseenter="showText(item.show = true)"
           @mouseleave="hideText(item.show = false)" @click="goDetail(item.eventId)">
        <img
          :src="item.eventUrl"
          alt=""
          height="162.83px"
          width="244.25px"
        />
        <p v-if="item.show" class="showdec">
          <span v-if="item.eventHdstatus == '1'">待开始</span>
          <span v-if="item.eventHdstatus == '2'">进行中</span>
          <span v-if="item.eventHdstatus == '3'">已结束</span>
        </p>
        <el-tooltip
          :content="item.eventTitle"
          placement="bottom"
          effect="light"
        >
          <div
            class="recactitemname"
            @mouseenter="showText(item.show = true)"
            @mouseleave="hideText(item.show = false)"
          >
            {{item.eventTitle}}
          </div>
        </el-tooltip>
        <div class="recactitemmoney">¥{{item.eventPrice}}</div>
      </div>

    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import api from '@/common/request/request'
import imgUrl from "@/common/imgUrl";
import router from "@/router";
export default defineComponent({
  name: "recommend",
  data() {
    return {
      show: false,
      activities: []
    };
  },
  created() {

    this.getRecommend();
  },
  methods: {
    showText() {
      this.show = true;
    },
    goDetail(id) {
      router.push({
        path: '/activities/info',
        query: {
          id: id
        }
      })
    },
    hideText() {
      this.show = false;
    },
    getRecommend() {
      api.getActivityList({
        eventStatus:''
      }).then(res => {
        for(let i=0;i<res.data.rows.length;i++) {
          res.data.rows[i].eventUrl = imgUrl + JSON.parse(res.data.rows[i].eventUrl)[0].url
        }
        this.activities = res.data.rows
      })
    }
  },
});
</script>
<style scoped>
.recact {
  width: 1080px;
  height: 348px;
  border: 1px solid #ccc;
  margin: 30px auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

}
.recacttitle {
  padding: 20px 0 24px 25px;
  font-size: 24px;
  color: #333;
  width: 100%;
}
.recactcontent {
  display: flex;
  padding-left: 24px;
  padding-bottom: 24px;
  justify-content: space-between;
  overflow: hidden;
}
.recactitem {
  border: 1px solid #ccc;
  margin-right: 20px;
  width: 244.25px;
  cursor: pointer;
  position: relative;
  /* height: 100px; */
}
.showdec {
  position: absolute;
  width: 100%;
  height: 162.83px;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 162.83px;
  color: #fff;
  text-align: center;
  top: 0;
}
.showdec span {
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 5px;
}
.recactitemname {
  padding: 10px 14px 4px;
  height: 32px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  overflow: hidden;
}
.recactitemmoney {
  padding: 5px 14px 10px;
  line-height: 24px;
  color: #fe8340 !important;
  font-size: 18px;
}
.recactitemname:hover {
  color: #fe8340 !important;
}
</style>
