<!-- src/pc/PCPage.vue -->
<template>
  <div>
    <Header v-on:MenuList="handleMenuList"></Header>
    <!-- PC端页面内容 -->
    <div class="about" v-if="voteInfo">
      <div class="container2">
        <div>
          <div>
            <div class="bread">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"
                  >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item :to="{ path: '/vote' }"
                  >网上投票</el-breadcrumb-item
                >
                <!-- <el-breadcrumb-item>{{
                  voteInfo.voteTitle
                }}</el-breadcrumb-item> -->
                <el-breadcrumb-item><div style="max-width: 700px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;font-size: 16px;line-height: 16px;">{{ voteInfo.activityTitle}}</div></el-breadcrumb-item>

                
              </el-breadcrumb>
            </div>
            <div class="content main" style="margin: 20px 0 20px 0px">
              <div
                class="active-title"
                style="margin-left: 10px; margin-top: 20px"
              >
                {{ voteInfo.activityTitle }}
              </div>
              <div class="active-content">
                <div class="active-img">
                  <el-carousel style="width: 480px; height: 300px">
                    <el-carousel-item
                      v-for="(item, index) in voteInfo.activityImgs"
                      :key="item"
                    >
                      <img
                        style="width: 480px; height: 300px"
                        alt
                        :src="
                          item.substring(0, 4) == '/api' ? item : '/api' + item
                        "
                      />
                    </el-carousel-item>
                  </el-carousel>
                  <!-- <div class="active-img-box">
                    <div
                      v-for="(img, index) in applicationInfo.activityImgs"
                      :key="index"
                    >
                      <img
                        alt
                        v-if="index === currentImgIndex"
                        :src="img.substring(0,4) == '/api' ? img : '/api' + img"
                      />
                    </div>
                  </div> -->
                  <!-- v-for="(img2, index2) in activityDetail.eventUrl"
                        :key="index2"
                        @click="currentImgIndex = index2"
                        :class="{ active: index2 === currentImgIndex }" -->
                  <!-- <div class="active-img-list">
                    <div
                      class="active-img-item"
                      v-for="(img2, index2) in applicationInfo.activityImgs"
                      :key="index2"
                      @click="currentImgIndex = index2"
                      :class="{ active: index2 === currentImgIndex }"
                    >
                      <img
                        :src="img2.substring(0,4) == '/api' ? img2 : '/api' + img2"
                        alt
                        v-if="index2 <= 3"
                      />
                    </div>
                  </div> -->
                </div>
                <div class="active-info">
                  <div class="active-price">
                    <div class="active-price-box">
                      投票分类名称：
                      <span>{{this.classifyName}}</span>
                    </div>
                  </div>
                  <div class="active-time">
                    投票时间：{{ voteInfo.startTime }}至{{
                      voteInfo.stopTime
                    }}
                  </div>
                  <el-divider border-style="dashed" />

                  <div class="active-address-notop">
                    投票间隔：{{ voteInfo.voteJg =='1'?'仅一次':'每天' }}
                  </div>
                  <div class="active-address">
                    投票次数：{{ voteInfo.voteNumber }}
                  </div>
                  <div class="active-address">
                    <!-- <el-button type="primary" size="large">我要投票</el-button> -->
                    <!-- v-if="activityDetail.eventHdstatus == '2'" -->
                    <!-- <el-button
                        type="primary"
                        size="large"
                        disabled
                        v-if="activityDetail.eventHdstatus == '1'"
                        >未开始报名</el-button
                      >
                      <el-button
                        type="info"
                        size="large"
                        disabled
                        v-if="activityDetail.eventHdstatus == '3'"
                        >已截止报名</el-button
                      > -->
                  </div>
                </div>
              </div>
            </div>
            <!-- 左侧内容 -->
            <div class="contentView main">
              <div
                style="
                  padding-left: 10px;
                  margin-top: 20px;
                  margin-bottom: 20px;
                  font-size: 20px;
                  font-weight: 700;
                "
              >
                活动简介
              </div>
              <div
                v-html="voteInfo.activityContent"
                style="
                  text-indent: 2em;
                  line-height: 1.5em;
                  font-size: 16px;
                  color: #333;
                  padding: 0 10px;
                "
              ></div>
              <div
                style="
                  padding-left: 10px;
                  margin-top: 20px;
                  margin-bottom: 20px;
                  font-size: 20px;
                  font-weight: 700;
                "
              >
                选手管理
              </div>
              <div class="huodong">
                <div style="margin-right: 15px;">选手姓名/编号</div>
                <el-input
                  placeholder="请输入选手姓名/编号"
                  v-model="search2"
                  class="input-with-select"
                  clearable
                >
                </el-input>
                <el-button @click="search" class="sanhuibutton">搜索</el-button>
              </div>
              <!-- <div class="playerlist">
                <div
                  class="playeritem"
                  v-for="item in playerList"
                  :key="item.playerId"
                >
                  <div class="playerimg">
                    <img :src="item.playerImg.substring(0,4) == '/api' ? item.playerImg : '/api' + item.playerImg" alt />
                  </div>
                  <div class="playerinfo">
                    <div class="playername">选手姓名：{{ item.playerName }}</div>
                    <div >
                      <el-button @click="lookplayer(item.playerId)" style="background-color: #b52024;color: #fff;">查看详情</el-button>
                      <el-button @click="voteplayer(item)" v-if="voteInfo.voteStatus == '1'" style="background-color: #b52024;color: #fff;">投票</el-button>
                      <el-button disabled v-if="voteInfo.voteStatus == '0'" type="info" style="background-color: #c8c9cc !important;">投票未开始</el-button>
                      <el-button disabled v-if="voteInfo.voteStatus == '2'" type="info" style="background-color: #c8c9cc !important;">投票已结束</el-button>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="activity-list">
              <div
                class="activity-item"
                v-for="(item, index) in playerList"
                :key="item.playerId"
              >
                <div class="activity-item-img">
                  <img :src="item.playerImg.substring(0,4)=='/api'?item.playerImg:'/api'+ item.playerImg" alt="" />
                  <!-- <div class="activity-item-price">￥1111111111</div> -->
                </div>
                <div class="activity-item-info">
                  <div class="activity-item-title">
                    选手姓名：{{ item.personName }}
                  </div>
                  <div class="activity-item-title">
                    选手编号：{{item.playerSerial}}
                  </div>
                  <div >
                      <el-button @click="lookplayer(item.personId)" style="background-color: #b52024;color: #fff;">查看详情</el-button>
                      <el-button @click="voteplayer(item)" v-if="voteInfo.activityStatus == '1'" style="background-color: #b52024;color: #fff;">投票</el-button>
                      <el-button disabled v-if="voteInfo.activityStatus == '0'" type="info" style="background-color: #c8c9cc !important;">投票未开始</el-button>
                      <el-button disabled v-if="voteInfo.activityStatus == '2'" type="info" style="background-color: #c8c9cc !important;">投票已结束</el-button>
                    </div>
                </div>
              </div>
            </div>
            <el-empty
            v-if="this.playerList.length == 0"
            description="暂无数据"
          ></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-empty style="min-height: calc(100vh - 290px);" v-else description="暂无数据"></el-empty>
    <Footer></Footer>
    <el-dialog v-model="dialogVisible" title="投票确认" width="500" :before-close="handleClose">
      <div>您确定投票给该选手吗？</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirm()">确认</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible2" title="投票须知" width="800" :before-close="handleClose2">
      <div style="white-space: pre-line;">{{noticeData}}</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取消</el-button>
          <el-button type="primary" @click="confirm2()">确认</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// 页面逻辑
import Header from "@/components/header.vue"; // 引入头部组件
import breadcrumbs from "@/components/Breadcrumbs.vue"; // 引入面包屑组件
import LatestActivities from "@/components/LatestActivities.vue"; // 引入最新活动组件
import Footer from "@/components/Footer.vue";
import mobileHeader from "@/components/mobileHeader.vue"; // 引入头部组件
import navs from "@/components/nav.vue"; // 引入导航组件
let img = require("@/static/img/banner.jpg"); // 引入轮播图图片
import api from "@/common/request/request";
import apiuser from "@/common/request/user";
import imgUrl from "@/common/imgUrl";
import router from "@/router";
import { toRaw } from "@vue/reactivity";

export default {
  // 页面逻辑
  components: {
    Header, // 注册头部组件
    breadcrumbs, // 注册面包屑组件
    LatestActivities, // 注册最新活动组件
    Footer, // 注册底部组件
    mobileHeader, // 注册移动端头部组件
    navs, // 注册导航组件
  },
  data() {
    return {
      voteInfo: null,
      currentImgIndex: 0, // 当前轮播图索引
      playerList: [],
      dialogVisible:false, // 投票确认弹窗
      dialogVisible2:false, // 投票确认弹窗
      playerInfo: null, // 选手信息
      classifyList:[],
      classifyName:'',
      noticeData:'',
      search2:'',
      id:'',
      classifyName:''
    };
  },

  watch: {
    // $route(to, from) {
    //     if (this.$route.query.status) {
    //         this.status = this.$route.query.status
    //         this.getActivityLists() // 获取推荐活动数据
    //     }
    // }
  },
  created() {
     this.id = this.$route.query.id;
    this.getVoteInfo(); // 获取活动详情
    this.getPlayerList();
    this.getNoticeInfo(); // 获取公告信息
    // this.getApplicationClassify(); // 获取分类列表
  },
  mounted() {
    // 页面挂载
  },
  methods: {

    getNoticeInfo(){
      console.log('111111111')
      api.getNoticeList('vote_config').then((res) => {
        console.log(res);
        this.noticeData = res.data.msg;
      });
    },
   getApplicationClassify(){
      api.getApplicationClassifyHd().then((res) => {
        console.log(res);
        this.classifyList = res.data.data;
        console.log("分类列表", this.classifyList);
        this.classifyList.forEach(item=>{
          if(item.classifyId==this.voteInfo.classifyId){
            this.classifyName = item.classifyName;
          }
        })
      })
    },
    handleClose(){
      this.dialogVisible = false;
    },
    lookplayer(id){
      this.$router.push({path: '/vote/playerInfo', query: {id: id,activityId:this.voteInfo.activityId,activityStatus:this.voteInfo.activityStatus}})
    },
    search(){
      this.getPlayerList();
    },
    getPlayerList() {
      api.getPersonListAll({ activityId: this.id , search:this.search2 }).then((res) => {
        console.log(res);
        this.playerList = res.data.data;
        console.log(this.playerList);
      });
    },
    getVoteInfo() {
      api.getApplicationInfo(this.id).then((res) => {
        console.log(res);
        this.voteInfo = res.data.data;
        this.voteInfo.activityImgs =
          this.voteInfo.activityImgs.split(",");
          this.getApplicationClassify()
      });
    },
    voteplayer(item){
      if (
        !localStorage.getItem("zsjyuserInfo") ||
        localStorage.getItem("zsjyuserInfo") == null ||
        localStorage.getItem("zsjyuserInfo") === "null"
      ) {
        this.$message.error("请先登录");
        return false;
      }
      this.dialogVisible2 = true;
      this.playerInfo = item;
    },
    confirm2(){
      this.dialogVisible2 = false;
      this.dialogVisible = true;
    },
    confirm() {
      console.log(JSON.parse(localStorage.getItem("zsjyuserInfo")));
      // return false;
      var data={
        voteId: this.voteInfo.activityId,
        playerId: this.playerInfo.personId,
        userName: JSON.parse(localStorage.getItem("zsjyuserInfo")).phone,
        logNumber:'1'
      }
      api.addVoteLog(data).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success("投票成功");
          this.$router.push({path: '/vote'})
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
    })
    }
  },
};
</script>
<style>

</style>
<style scoped lang="scss">
.itembtn {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 14px;
    :nth-child(1) {
      // background-color: #b52024;
      padding: 5px 10px;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
    }
    :nth-child(2) {
      // background-color: #b52024;
      padding: 5px 10px;
      color: #fff;
      border-radius: 5px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
.playerlist{
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.playeritem{
  width: 300px;
  // border:1px solid #ccc;
  height: 100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 20px;
  margin-right: 15px;
  margin-bottom: 20px;
}
.playerimg{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  img{
    width: 60px;
  height: 60px;
  border-radius: 50%;
  }
}
.playerinfo{
  padding-left: 20px;
  margin-top: 20px;
}
</style>
<style lang="scss" scoped>
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  &:hover {
    color: #b52024 !important;
  }
}
.about {
  .el-breadcrumb {
    font-size: 16px;
  }
  // background-image: url(../assets/bg.png);
  .container2 {
    min-height: calc(100vh - 290px);
    overflow: auto;
    width: 1080px;
    margin: 0 auto;
    background-color: #fff;
  }
  .bread {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 20px;
    margin-top: 36px;
    margin-left: 10px !important;
    > span {
      padding-right: 10px;
    }
  }
  .infotitle {
    text-align: center;
    margin-top: 68px;
    font-size: 32px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 34px;
    margin-left: 44px;
    margin-right: 44px;
  }
  .infofeilei {
    display: flex;
    justify-content: space-around;
    margin-top: 18px;
    font-size: 16px;
    color: #858585;
  }
  .infocontent {
    margin-top: 44px;
    margin-left: 48px;
    margin-bottom: 44px;
    margin-right: 44px;
  }
}
.huodong {
  //   margin-top: 51px;
  // width: 400px;
  margin-right: 20px;
  display: flex;
  margin-left: 20px;
  align-items: center;
  justify-content: flex-end;
  //   margin-left: 66px;
  margin-top: -45px;
  .el-icon-search:before {
    color: #fff;
  }
  .el-input{
  width: 200px;
}
}
.sanhuibutton {
  margin-left: 10px;
  background-color: #d71212 !important;
  color: #fff !important;
}
.sanhuibutton:hover {
  margin-left: 10px;
  background-color: #d71212;
  color: #fff;
}
.activity-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}
.activity-item {
  margin-bottom: 20px;
  position: relative;
  width: 250px;
  height: 320px;
  background: #f3f3f3;
  display: inline-block;
  margin-right: 21px;
  cursor: pointer;
}
.activity-item:nth-child(4n) {
  margin-right: 0;
}
.activity-item-img {
  width: 100%;
  height: 180px;
  position: relative;
}
.activity-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-item-title {
  width: 100%;
  height: 42px;
  color: #f86532;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.activity-item-info {
  padding: 10px;
}
.activity-item-desc {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-time {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 14px;
}
.activity-item-location {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-item-btn {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}
.activity-item-price {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #f86532;
  background: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
}
.el-check-tag {
  margin-bottom: 10px;
}
.activity-condition {
  display: flex;

  margin-bottom: 20px;
  margin-left: 36px;
  margin-top: 20px;
}

.condition-title {
  font-size: 16px;
  color: #444;
  flex-shrink: 0;
}
.condition-content {
  display: flex;

  margin-left: 20px;
  flex-wrap: wrap;
}
.content {
  box-sizing: border-box;
}
.active-title {
  font-size: 20px;
  font-weight: bold;
}
.active-content {
  display: flex;
}
.active-img {
  width: 500px;
  flex-shrink: 0;
}
.active-img-box {
  width: 100%;
  height: 320px;
}
.active-img-box img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}
.active-img-list {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
}
.active-img-item {
  width: 116px;
  height: 73px;
  border: 2px solid #ddd;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
}
.active-img-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-info {
  margin-left: 20px;
  width: 100%;
}
.active-time {
  padding: 10px 15px 0;
  font-size: 16px;
  color: #666;
}
.active-address {
  padding: 10px 15px 0;
  font-size: 16px;
  color: #666;
}
.active-address-notop {
  padding: 0 15px 0;
  font-size: 16px;
  color: #666;
}
.active-content {
  padding: 10px 10px 0 !important;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}
.active-price {
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active-price-box {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.active-price-peo {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
}
.contentView {
  margin-top: 20px;
}
.contentViewBox {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}
.img {
  max-width: 100%;
}
.active1 {
  border: 2px solid #409eff;
}
.active-price-box span {
  color: #ff4949;
}
</style>
