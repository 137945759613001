<script>
import {defineComponent} from 'vue'
import api from '@/common/request/request'
import imgUrl from "@/common/imgUrl";
import router from "@/router";
export default defineComponent({
  name: "LatestActivities",
  data() {
    return {
      activities: []
    }
  },
  created() {
    api.getActivityList({
      eventStatus:''
    }).then(res => {
      for(let i=0;i<res.data.rows.length;i++) {
        res.data.rows[i].eventUrl = imgUrl + JSON.parse(res.data.rows[i].eventUrl)[0].url
      }
      this.activities = res.data.rows
    })
  },
  methods: {
    goDetail(id) {
      router.push({
        path: '/activities/info',
        query: {
          id: id
        }
      })
    },
  }
})

</script>

<template>
  <div>
    <div class="top-content" v-for="(activity, index) in activities" :key="index" @click="goDetail(activity.eventId)" >
      <div class="top-content-right" v-if="index < 4">
        <div class="top-content-content">
          <img :src="activity.eventUrl" alt="" />
          <div class="top-content-content-title">{{activity.eventTitle}}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.top-content-right-content {
  display: block;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  padding:0 20px;
}
.active {

  color: #f86532;
}

.top-content-content {
  width: 200px;
  height: 120px;
  position: relative;text-align: center;
  margin-bottom: 5px;
  cursor: pointer;
}
.top-content-content img{
  width: 100%;
  height: 100%;
  object-fit: cover;

}
.top-content-content-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: rgba(0,0,0,0.5);
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding:0 10px;
}

.top-content-left {
  width: 30%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.activity-title {

}
</style>
