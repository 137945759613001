<!-- src/pc/PCPage.vue -->
<template>
  <div>
    <Header></Header>
    <!-- PC端页面内容 -->

    <div class="content main">
      <div class="content-container" v-if="applicateDeatil">
        <div class="title-container" v-if="applicateDeatil.zsjyActivity">活动信息</div>
        <div class="content" v-if="applicateDeatil.zsjyActivity">
          <div class="content-item">
            活动名称：{{ applicateDeatil.zsjyActivity.activityTitle }}
          </div>
          <div class="content-item">
            投票间隔：{{ applicateDeatil.zsjyActivity.voteJg=='1'?'仅一次':'每天' }}
          </div>
          <div class="content-item">
            投票次数：{{ applicateDeatil.zsjyActivity.voteNumber }}
          </div>
          <div class="content-item">
            开始时间：{{ applicateDeatil.zsjyActivity.startTime }}
          </div>
          <div class="content-item">
            结束时间：{{ applicateDeatil.zsjyActivity.stopTime }}
          </div>
          <!-- <div class="content-item">
              批次：
              <span v-for="(item, index) in orderList.pcs" :key="index">
                <span v-if="item.pcId == orderList.qnsjEvent.pcId">{{
                  item.pcContent
                }}</span>
              </span>
            </div> -->
          <!-- <div class="content-item">单价：{{ orderList.orderDanPrice }}</div> -->
        </div>
        <div class="title-container">投票人</div>
        <div class="content">
          <div class="content-item">用户名：{{ applicateDeatil.createBy }}</div>
          <div class="content-item">
            投票时间：{{ applicateDeatil.createTime }}
          </div>
        </div>
        <div class="title-container" v-if="applicateDeatil.zsjyPerson">选手信息</div>
        <div class="content" v-if="applicateDeatil.zsjyPerson">
          <div class="content-box">
            <div class="content-item">
              编号：{{applicateDeatil.zsjyPerson.playerSerial}}
            </div>

            <div class="content-item">
              姓名：{{ applicateDeatil.zsjyPerson.personName }}
            </div>
            <div class="content-item">
              总票数：{{ applicateDeatil.zsjyPerson.number2 }}
            </div>
            <!-- <div class="content-item">电话：{{ applicateDeatil.zsjyPlayer.personPhone }}</div>
              <div class="content-item">性别：{{ applicateDeatil.zsjyPlayer.personSex == '1' ?'女':'男' }}</div>
              <div class="content-item">年龄：{{ applicateDeatil.zsjyPlayer.personAge }}</div> -->
          </div>
        </div>
      </div>

      <div class="button-container">
        <el-button type="primary" @click="goback()">返回</el-button>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import navs from "@/components/nav";
import { showImagePreview } from "vant";
import Footer from "@/components/Footer.vue"; // 引入头部组件
import Header from "@/components/header.vue"; // 引入头部组件
import recommend from "@/components/recommend.vue"; // 引入头部组件
import breadcrumbs from "@/components/Breadcrumbs.vue"; // 引入面包屑组件
let img = require("@/static/img/sq15231168926.jpg"); // 引入轮播图图片
let img2 = require("@/static/img/sh18034017788.jpg"); // 引入轮播图图片

import router from "@/router";
import api from "@/common/request/request";
import imgUrl from "@/common/imgUrl";
import LatestActivities from "@/components/LatestActivities.vue";
import QRCode from "qrcodejs2";
import wx from "weixin-js-sdk";
export default {
  components: {
    navs,
    Header,
    recommend,
    breadcrumbs,
    LatestActivities,
    Footer,
  },
  // 页面逻辑
  data() {
    return {
      applicateDeatil: {
        zsjyActivity:{
          activityTitle:''
        },
        zsjyPerson:{
          playerSerial:''
        }
      },
      classifyList:null,
      classifyName:null,
    };
  },

  created() {
    let id = this.$route.query.id;
    this.getDetail(id);
  },
  methods: {
    goback(){
        this.$router.push({path:'/user/voteList'});
    },
    getDetail(id) {
      api.getVoteLogInfo(id).then((res) => {
      //   this.dialogVisible = true;
        console.log(res);
        this.applicateDeatil = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
/* PC端页面的样式 */

.breadcrumb-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
}

.SeBox {
  margin-left: 20px;
  margin-top: 10px;
}

.SeBox button {
  width: 60px;
  height: 30px;
  background-color: #409eff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.content-left {
  width: 228px;
  background-color: #fff;
  flex-shrink: 0;
  padding: 15px;
  box-sizing: border-box;
}
.content-right {
  background-color: #f5f5f5;
  overflow: hidden;
  padding: 30px;
  width: 100%;
}
.content-container {
  position: relative;
  min-height: calc(100vh - 382px);
}
.content {
  margin: 20px auto;
  box-sizing: border-box;
}
.activity-condition {
  display: flex;

  align-items: center;
  margin-bottom: 20px;
}

.condition-title {
  font-size: 16px;
  color: #444;
}
.condition-content {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.top-container {
  width: 100%;
  border-bottom: 1px solid #eee;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}
.top-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 15px;
  color: #444;
  border-bottom: 1px solid #eee;
}
.top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.top-content-right-content {
  display: block;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  padding: 0 20px;
}
.active {
  color: #f86532;
}

.top-content-content {
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.top-content-content img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}
.top-content-content-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
}

.top-content-left {
  width: 30%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.activity-title {
}
.activity-item {
  margin-bottom: 20px;
  position: relative;
  width: 250px;
  height: 300px;
  background: #f3f3f3;
  display: inline-block;
  margin-right: 21px;
}
.activity-item:nth-child(3n) {
  margin-right: 0;
}
.activity-item-img {
  width: 100%;
  height: 180px;
  position: relative;
}
.activity-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-item-title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #353535;
  font-size: 14px;
  font-weight: bold;
}
.activity-item-info {
  padding: 10px;
}
.activity-item-desc {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
}
.activity-item-time {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
}
.activity-item-location {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
}
.activity-item-btn {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}
.activity-item-price {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
}
.mint-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.userubox {
  margin-top: 10px;
}
.userubox-item {
  margin-bottom: 10px;
  padding: 10px;
  background: #f3f3f3;
  border-radius: 4px;
}
.dialog-content {
  line-height: 24px;
}
.img-box {
  width: 150px;
  height: 150px;
}
.img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content-title {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 20px;
}
.title-container {
  padding: 5px 10px;
  background: #f3f3f3;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 20px;
}
.content-desc {
  font-size: 14px;
  color: #f86532;
  margin-bottom: 20px;
}
.content-item {
  display: inline-block;

  width: 48%;
  line-height: 34px;
  font-size: 14px;
  color: #666;
}
.content {
  padding: 0 5px;
}
.qrcode {
  object-fit: contain;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  heigth: 200px;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.status {
  font-size: 14px;
  color: #f86532;
  margin-left: 20px;
  font-weight: bold;
}
</style>
