<script>
import api from '@/common/request/request'
import imgUrl from "@/common/imgUrl";
import router from "@/router";
export default {
  name: "mobileRecommend",
  data() {
    return {
      activities: []
    }
  },
  created() {
    this.getActivities()
  },
  methods: {
    getActivities() {
      api.getActivityList({
        eventStatus:''
      }).then(res => {
        var arr = []
        for(let i=0;i<res.data.rows.length;i++) {
          res.data.rows[i].eventUrl = imgUrl + JSON.parse(res.data.rows[i].eventUrl)[0].url
          if(i<4) {
            arr.push(res.data.rows[i])
          }
        }
        this.activities = arr
      })
    },
    goDetail(id) {
      router.push({
        path: '/activities/info',
        query: {
          id: id
        }
      })
    },
  }
}
</script>

<template>
  <div>
    <div class="active-item2">
      <div class="active-item2-flex" v-for="(item,index) in activities" :key="index" @click="goDetail(item.eventId)">
        <div class="active-item-img2">
          <img :src="item.eventUrl" alt="" />
        </div>
        <div class="active-item2-right">
          <div class="active-item-title">
           {{item.eventTitle}}
          </div>
          <div class="active-item-price2">￥{{item.eventPrice}}</div>
          <div class="active-item-btn" v-if="item.eventHdstatus == '1'">
            <span >待开始</span>
          </div>
          <div class="active-item-btn bgain" v-if="item.eventHdstatus == '2'">
            <span >进行中</span>
          </div>
          <div class="active-item-btn" v-if="item.eventHdstatus == '3'">
            <span >已结束</span>
          </div>
        </div>
      </div>



    </div>
  </div>
</template>

<style scoped>
.active-item2 {
  margin-bottom: 12px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  vertical-align: top;
  margin-right: 4%;
}
.active-item2-flex {
  display: flex;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #e4e4e4;
  justify-content: space-between;
}
.active-item-img2 {
  position: relative;
  width: 45%;
  height: 130px;
  display: flex;
  align-items: center;
  padding: 1% 0% 1% 0%;
}
.active-item-img2 img {
  width: 100%;
  height: 80%;
  border-radius: 5%;
  object-fit: cover;
}
.active-item2-right {
  padding: 3% 0% 5% 1%;
  width: 54%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.active-item-price2 {
  padding: 3px 10px 10px;
  display: inline-block;
  font-size: 12px;
  color: #666;
  margin-top: 5%;
}
.active-item-btn {
  width: 90%;
  margin: 0 auto;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  background-color: #999;
  border-radius: 20px;
  /* margin-bottom: 10%; */
}
/* 客照 */

.active-item3 {
  margin-bottom: 12px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}
.active-item3-flex{
  /* width: 120px; */
  /* display: inline-block; */
  overflow-x: auto;
  height: 120px;
  padding: 2%;
  display: flex;

  white-space: nowrap;

}
.active-item3-flex:last-child{
  padding-right: 0;
}
.active-item-img3 {
  flex: 0 0 auto;
  float: left;
  position: relative;
  width: 120px;
  margin-right: 2%;

  height: 100%;
}
.grew{
  background-color: #999;
  border-radius: 10px;
}
.active-item-more{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.active-item-img3 img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.active-item-img4 {
  flex: 0 0 auto;
  float: left;
  position: relative;
  width: 220px;
  margin-right: 2%;

  height: 100%;
}
.active-item-img4 video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.bgain {
  background-color: #ff9900 !important;
}
</style>
