<!-- src/pc/PCPage.vue -->
<template>
  <div>
    <Header></Header>
    <!-- PC端页面内容 -->
    <div class="main breadcrumb-container">
      <!--  引入面包屑组件-->
      <div class="bread">
        <span>您当前的位置:</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/user' }"
            >个人中心</el-breadcrumb-item
          >
          <el-breadcrumb-item>个人资料</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="content main">
      <div class="content-container">
        <div class="content-left">
          <div class="top-container">
            <div class="top-title">个人中心</div>
            <div class="top-content">
              <div class="top-content-right">
                <div
                  class="top-content-right-content"
                  @click="navToPage('/user')"
                >
                  我的主页
                </div>
                <div class="top-content-right-content active">我的资料</div>

                <div
                  class="top-content-right-content"
                  @click="navToPage('/user/applicateList')"
                >
                  我的报名
                </div>
                <div
                  class="top-content-right-content"
                  @click="navToPage('/user/voteList')"
                >
                  我的投票
                </div>
                <div
                  class="top-content-right-content"
                  @click="navToPage('/user/orderList')"
                >
                  我的订单
                </div>
                <!-- <div class="top-content-right-content" @click="navToPage('/user/integral')">
                    我的积分
                  </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="mint-container" v-if="!formAdd">
            <div class="mint-title">我的信息</div>
            <div class="mint-content">
              <div class="mint-content-item">
                <div class="mint-content-item-title">头像</div>
                <div class="mint-content-item-content">
                  <img
                    class="avatar"
                    :src="'/api' + form.userPortrait"
                    alt="头像"
                  />
                </div>
              </div>
              <div class="mint-content-item">
                <div class="mint-content-item-title">昵称</div>
                <div class="mint-content-item-content">
                  {{ form.userNickname ? form.userNickname : "暂无" }}
                </div>
              </div>
              <!--              <div class="mint-content-item">-->
              <!--                <div class="mint-content-item-title">真实姓名</div>-->
              <!--                <div class="mint-content-item-content">{{form.userPhone}}</div>-->
              <!--              </div>-->
              <div class="mint-content-item">
                <div class="mint-content-item-title">性别</div>
                <div class="mint-content-item-content" v-if="form.userGender">
                  {{ form.userGender == 0 ? "男" : "女" }}
                </div>
                <div class="mint-content-item-content" v-else>暂无</div>
              </div>
              <div class="mint-content-item">
                <div class="mint-content-item-title">密码</div>
                <div class="mint-content-item-content">********</div>
              </div>
              <div class="mint-content-item">
                <div class="mint-content-item-title">手机</div>
                <div class="mint-content-item-content">
                  {{ form.phone ? form.phone : "暂无" }}
                </div>
              </div>
              <div class="mint-content-item">
                <div class="mint-content-item-title">身份证号</div>
                <div class="mint-content-item-content">
                  {{ form.idNumber ? form.idNumber : "暂无" }}
                </div>
              </div>
              <div class="mint-content-item">
                <div class="mint-content-item-title">居住地</div>
                <div class="mint-content-item-content">
                  {{ form.userSite ? form.userSite : "暂无" }}
                </div>
              </div>
              <!--              <div class="mint-content-item">-->
              <!--                <div class="mint-content-item-title">兴趣爱好</div>-->
              <!--                <div class="mint-content-item-content">{{form.hobby?form.hobby:'暂无'}}</div>-->
              <!--              </div>-->
              <div class="mint-content-item">
                <div class="mint-content-item-title">创建时间</div>
                <div class="mint-content-item-content">
                  {{ form.createTime ? form.createTime : "暂无" }}
                </div>
              </div>
              <!-- <div class="mint-content-item">
                  <div class="mint-content-item-title">最近登录时间</div>
                  <div class="mint-content-item-content">{{form.userDesc?form.userDesc:'暂无'}}</div>
                </div> -->
            </div>
            <div class="button-container">
              <el-button type="primary" @click="formAdd = true"
                >修改信息</el-button
              >
            </div>
          </div>
          <div class="mint-container" v-if="formAdd">
            <div class="mint-title">修改信息</div>
            <div class="mint-content">
              <div class="mint-content-item">
                <div class="mint-content-item-title">头像</div>
                <div class="mint-content-item-content">
                  <!-- /api/pcsystem/ZsjyAppUser/portrait -->
                  <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    action="/api/pcsystem/ZsjyAppUser/portrait"
                    :data="{ type: 'avatar' }"
                    headers="{Content-Type: 'multipart/form-data'}"
                  >
                    <img
                      v-if="form.userPortrait"
                      :src="'/api' + form.userPortrait"
                      class="avatar"
                    />
                    <el-icon v-else class="avatar-uploader-icon"
                      ><Plus
                    /></el-icon>
                  </el-upload>
                </div>
              </div>
              <div class="mint-content-item">
                <div class="mint-content-item-title"><span>*</span>昵称</div>
                <div class="mint-content-item-content">
                  <el-input
                    v-model="form.userNickname"
                    placeholder="请输入昵称"
                  />
                </div>
              </div>
              <div class="mint-content-item">
                <div class="mint-content-item-title">性别</div>
                <div class="mint-content-item-content">
                  <el-radio-group v-model="form.userGender">
                    <el-radio label="0">男</el-radio>
                    <el-radio label="1">女</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="mint-content-item">
                <div class="mint-content-item-title">密码</div>
                <div class="mint-content-item-content">******</div>
                <el-button
                  style="margin-left: 10px"
                  type="text"
                  @click="showPasswordDialog"
                  >修改密码</el-button
                >
              </div>
              <!-- <div class="mint-content-item">
                <div class="mint-content-item-title"><span>*</span> 手机</div>
                <div class="mint-content-item-content">
                  <el-input v-model="form.phone" placeholder="请输入手机号" />
                </div>
              </div> -->
              <div class="mint-content-item">
                <div class="mint-content-item-title">
                  <span>*</span>身份证号
                </div>
                <div class="mint-content-item-content">
                  <el-input
                    v-model="form.idNumber"
                    placeholder="请输入身份证号"
                  />
                </div>
              </div>
              <div class="mint-content-item">
                <div class="mint-content-item-title">居住地</div>
                <div class="mint-content-item-content">
                  <el-input
                    v-model="form.userSite"
                    placeholder="请输入居住地"
                  />
                </div>
              </div>
              <!--              <div class="mint-content-item">-->
              <!--                <div class="mint-content-item-title">兴趣爱好</div>-->
              <!--                <div class="mint-content-item-content">-->
              <!--                  <el-checkbox-group v-model="form.userDesc">-->
              <!--                    <el-checkbox label="足球">足球</el-checkbox>-->
              <!--                    <el-checkbox label="篮球">篮球</el-checkbox>-->
              <!--                  </el-checkbox-group>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
            <div class="button-container">
              <el-button type="primary" @click="onSubmit()">确认修改</el-button>
              <el-button type="info" @click="formAdd = false">返回</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <el-dialog title="修改密码" v-model="dialogVisible" width="550px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="新密码">
          <el-input
            type="password"
            v-model="password"
            placeholder="请输入包含字母，数字，特殊字符在内的6-12位的新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input
            type="password"
            v-model="confirmPassword"
            placeholder="请确认新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitPassword">确定</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Footer from "@/components/Footer.vue"; // 引入头部组件
import Header from "@/components/header.vue"; // 引入头部组件
import recommend from "@/components/recommend.vue"; // 引入头部组件
import breadcrumbs from "@/components/Breadcrumbs.vue"; // 引入面包屑组件
let img = require("@/static/img/qrcode.jpg"); // 引入轮播图图片
import router from "@/router";
import api from "@/common/request/request";
import imgUrl from "@/common/imgUrl";
import LatestActivities from "@/components/LatestActivities.vue";
import navs from "@/components/nav";
import axios from "axios";

export default {
  components: {
    navs,
    Header,
    recommend,
    breadcrumbs,
    LatestActivities,
    Footer,
  },
  // 页面逻辑
  data() {
    return {
      checked: ["1", "3", "4"],
      maxDate: "",
      minDate: new Date(1900, 0, 1),
      phone: "",
      email: "",
      birstday: [],
      idcard: "",
      address: "",
      showBottom: false,
      show: false,
      value1: [],
      form: {},
      fileList: "",
      value2: "",
      value3: "1",
      value4: "",
      password: null,
      confirmPassword: null,
      dialogVisible: false,

      img: img,
      // videoImg: "/api/profile/video/b8988d8ee5a14057ae96bd5a619e3361.avi", // 引入视频图片
      value: "",
      pattern: /\d{6}/,
      formAdd: false,
      oldForm: {},
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    handleAvatarSuccess(res) {
      console.log(res);
      this.form.userPortrait = res.AttachmentPath;
      // if (res.data.statusCode == 200) {
      //   /*因为是评价功能, 还需要把图片传到后台, 这里先存一下*/
      //   this.shopList.items[sub].uploadImgs.push(res.data.picture);
      // }
    },
    getUserInfo() {
      this.value1 = [];
      let userId = JSON.parse(localStorage.getItem("zsjyuserInfo")).phone;
      // 获取用户信息
      api.getUserInfo(userId).then((res) => {
        this.form = res.data.data;
        this.oldForm = res.data.data;
        console.log(res.data.data);
        if (res.data.data) {
          localStorage.setItem("zsjyuserInfo", JSON.stringify(res.data.data));
        }

        // localStorage.setItem('userInfo',res.data.data);
        this.value1.push({ url: "/api" + res.data.data.userPortrait });
      });
    },
    onSubmit(event) {
      console.log(event);
      console.log(this.fileList);
      console.log(this.form);
      if (this.form.userNickname == "" || !this.form.userNickname) {
        this.$message.error("请填写昵称");
        return;
      }
      if (this.form.phone == "" || !this.form.phone) {
        this.$message.error("请填写手机号码");
        return;
      }
      if (this.form.phone.length != 11 || !this.form.phone.match(/^1\d{10}$/)) {
        this.$message.error("手机号码格式不正确");
        return;
      }
      if (this.form.idNumber == "" || !this.form.idNumber) {
        this.$message.error("请填写身份证号码");
        return;
      }
      if (
        this.form.idNumber.length != 18 ||
        !this.form.idNumber.match(
          /^\d{6}(18|19|20)\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/
        )
      ) {
        this.$message.error("身份证号码格式不正确");
        return;
      }
      this.form.password = null;
      api.updateUserInfo(this.form).then((res) => {
        this.getUserInfo();
        console.log(res);
        if (res.data.code == 200) {
          this.$message.success(res.data.msg);
          let isPhone = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          );
          if (isPhone) {
            router.go(-1);
          } else {
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      // return `${date.getYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onSubmitPassword() {
      if (!this.password || this.password === "") {
        this.$message.error("请输入密码");
        return;
      }
      if (
        this.password &&
        !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\W_]).{6,12}$/.test(
          this.password
        )
      ) {
        this.$message.error(
          "请输入包含字母，数字，特殊字符在内的6-12位的密码"
        );
        return;
      }
      if (this.password !== this.confirmPassword) {
        this.$message.error("两次密码输入不一致");
        return;
      }
      let userId = JSON.parse(localStorage.getItem("zsjyuserInfo")).phone;
      let that = this;
      api.getUserInfo(userId).then((res) => {
        let form = res.data.data;
        form.userPassword = this.password;
        console.log(form);
        api.updateUserInfo(form).then((ress) => {
          console.log(ress);
          if (ress.data.code == 200) {
            that.$message.success(ress.data.msg);
            that.dialogVisible = false;
            router.push("/user");
            // localStorage.removeItem('userInfo')
            // let isPhone = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            // if(isPhone) {
            //     router.push('/login')
            // } else {
            //     router.push('/')
            // }
          } else {
            this.$message.error(ress.data.msg);
          }
        });
      });
    },
    showPasswordDialog() {
      this.dialogVisible = true;
    },
    onConfirm(value) {
      console.log(new Date(1900, 0, 1));
      this.show = false;
      this.form.birthday = this.formatDate(value);
    },
    saveXq() {
      this.showBottom = false;
      console.log(this.checked);
      this.form.hobby = this.checked.join(",");
    },
    opPopup() {
      this.showBottom = true;
    },
    onClickOverlay() {
      this.showBottom = false;
      this.checked = [];
    },
    onClickCloseIcon() {
      this.showBottom = false;
      this.checked = [];
    },
    validator(val) {
      return /^1\d{10}$/.test(val);
    },
    validatorMessage(val) {
      return `${val} 不合法，请重新输入`;
    },
    asyncValidator(val) {
      new Promise((resolve) => {
        // showLoadingToast("验证中...");

        setTimeout(() => {
          // closeToast();
          resolve(val === "1234");
        }, 1000);
      });
    },
    navToPage(path) {
      router.push({
        path: path,
      });
    },
    // onClick() {
    //   console.log("点击弹出评论框");
    // },
  },
};
</script>

<style scoped lang="scss">
/* PC端页面的样式 */
.bread {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  padding-top: 5px;
  font-size: 16px;
  margin-top: 26px;
  margin-left: 0px;
  > span {
    padding-right: 10px;
  }
}
.breadcrumb-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  .el-breadcrumb {
    font-size: 16px;
  }
}

.SeBox {
  margin-left: 20px;
  margin-top: 10px;
}

.SeBox button {
  width: 60px;
  height: 30px;
  background-color: #409eff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.content-left {
  width: 228px;
  background-color: #fff;
  flex-shrink: 0;
  padding: 15px;
  box-sizing: border-box;
}
.content-right {
  background-color: #fff;
  overflow: hidden;
  padding: 30px;
  width: 100%;
  border: 1px solid #eee;
}
.content-container {
  display: flex;
  position: relative;
  min-height: calc(100vh - 390px);
}
.content {
  margin: 20px auto;
  box-sizing: border-box;
}
.activity-condition {
  display: flex;

  align-items: center;
  margin-bottom: 20px;
}

.condition-title {
  font-size: 16px;
  color: #444;
}
.condition-content {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.top-container {
  width: 100%;
  border-bottom: 1px solid #eee;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}
.top-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 15px;
  color: #444;
  border-bottom: 1px solid #eee;
}
.top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.top-content-right-content {
  display: block;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  padding: 0 20px;
}
.active {
  color: #f86532;
}

.top-content-content {
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.top-content-content img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}
.top-content-content-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
}

.top-content-left {
  width: 30%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.activity-title {
}
.activity-item {
  margin-bottom: 20px;
  position: relative;
  width: 250px;
  height: 300px;
  background: #f3f3f3;
  display: inline-block;
  margin-right: 21px;
}
.activity-item:nth-child(3n) {
  margin-right: 0;
}
.activity-item-img {
  width: 100%;
  height: 180px;
  position: relative;
}
.activity-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-item-title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #353535;
  font-size: 14px;
  font-weight: bold;
}
.activity-item-info {
  padding: 10px;
}
.activity-item-desc {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
}
.activity-item-time {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
}
.activity-item-location {
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #353535;
  font-size: 12px;
}
.activity-item-btn {
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}
.activity-item-price {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: #ff6700;
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 2px 2px;
  padding: 0 10px;
}
.mint-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.userubox {
  margin-top: 10px;
}
.userubox-item {
  margin-bottom: 10px;
  padding: 10px;
  background: #f3f3f3;
  border-radius: 4px;
}
.dialog-content {
  line-height: 24px;
}
.img-box {
  width: 150px;
  height: 150px;
}
.img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mint-content-item-title {
  font-size: 14px;
  color: #999;
  font-weight: bold;
  width: 90px;
  text-align: right;
}
.mint-content-item-title span {
  color: #ff6700;
}
.mint-content-item-content {
  font-size: 14px;
  color: #222;
  margin-left: 20px;
}
.mint-content-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.mint-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 15px;
  color: #444;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}
</style>
