<!-- src/pc/PCPage.vue -->
<template>
  <div>
    <Header v-on:MenuList="handleMenuList"></Header>
    <!-- PC端页面内容 -->
    <div class="about">
      <div class="container">
        <div class="aside">
          <div>
            <span>您当前的位置:</span>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>赛区风采</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-button type="danger" class="button">赛区风采</el-button>
          <ul class="ulbox">
            <li v-for="(item,index) in classifySqList" :key="item.classifyId" @click="changeClassify(item,index)" :class="{active:item.classifyId==currentIndex}">{{item.classifyName}}</li>
          </ul>
        </div>
        <div class="content">
          <div>赛区风采</div>
          <!-- <el-empty v-if="this.dangjian.length==0" description="暂无数据"></el-empty> -->
          <!-- v-for="item in dangjian" :key="item.csId"@click="infoLook(item)" -->
          <div v-for="item in activityList" :key="item.articleId">
            <div class="conlist" @click="lookInfo(item.articleId)">
              <div class="listhead">
                <div class="headfirst" style="">
                  <div class="reddot" style=""></div>
                  <!-- <div class="listtitle" style="">{{ item.csHeadline }}</div> -->
                  <div class="listtitle" style="">{{ item.articleTitle }}</div>
                </div>
                <div class="listtime" style="">
                  {{ item.csCreattime }}
                </div>
              </div>
              <div class="listcon" style="">
                <span
                  style="text-indent: 2em"
                  v-html="item.articleContent.replace(/<\/?(img)[^>]*>/gi, '')"
                >
                </span>
                <!-- <span>11111111</span> -->
              </div>
            </div>
          </div>
          <el-empty
              v-if="this.activityList.length == 0"
              description="暂无数据"
            ></el-empty>
          <div class="block" >
            <div style="text-align: center">
              <el-pagination
                style="margin-top: 30px; margin-bottom: 20px;margin-right: 30px;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage1"
                :page-size="10"
                layout="->, prev, pager, next,"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// 页面逻辑
import Header from "@/components/header.vue"; // 引入头部组件
import breadcrumbs from "@/components/Breadcrumbs.vue"; // 引入面包屑组件
import LatestActivities from "@/components/LatestActivities.vue"; // 引入最新活动组件
import Footer from "@/components/Footer.vue";
import mobileHeader from "@/components/mobileHeader.vue"; // 引入头部组件
import navs from "@/components/nav.vue"; // 引入导航组件
let img = require("@/static/img/banner.jpg"); // 引入轮播图图片
import api from "@/common/request/request";
import apiuser from "@/common/request/user";
import imgUrl from "@/common/imgUrl";
import router from "@/router";
import { toRaw } from "@vue/reactivity";

export default {
  // 页面逻辑
  components: {
    Header, // 注册头部组件
    breadcrumbs, // 注册面包屑组件
    LatestActivities, // 注册最新活动组件
    Footer, // 注册底部组件
    mobileHeader, // 注册移动端头部组件
    navs, // 注册导航组件
  },
  data() {
    return {
      eventTitle: "",
      // 页面数据
      input2: "", // 搜索框数据
      activityList: [], // 推荐活动数据
      menuList: [], // 推荐音乐数据
      status: "",
      valueTime: "",
      startHdTime: "",
      stopHdTime: "",
      allTime: true,
      OrderslistGroupBy: [],
      eventDestination: "",

      SxType: "",

      img: img,
      // videoImg: "/api/profile/video/b8988d8ee5a14057ae96bd5a619e3361.avi", // 引入视频图片
      showPopup: false,
      value: "",
      data: {
        pageNum: 1,
        pageSize: 10,
        classifyId:'',
      },
      currentPage1:1,
      total:null,
      classifySqList:[],
      currentIndex:0
    };
  },

  watch: {
    $route(to, from) {
        if (this.$route.query.classifyId) {
          this.currentIndex = this.$route.query.classifyId
            this.data.classifyId = Number(this.$route.query.classifyId)
            this.getActivityList() // 获取推荐活动数据
        }
    }
  },
  created() {
  
    this.getClassifySqList()
  },
  mounted() {
    // 页面挂载
  },
  methods: {
    changeClassify(item,index){
      this.currentIndex = item.classifyId;
      this.data.classifyId = item.classifyId;
      this.getActivityList();
    },
    getClassifySqList() {
      api.getClassifySq().then(res=>{
        this.classifySqList = res.data.data
        this.currentIndex = this.classifySqList[0].classifyId
        this.data.classifyId=this.classifySqList[0].classifyId
        this.getActivityList();
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.data.pageNum = val;
      this.currentPage1 = val;
      this.getActivityList();
    },
    getActivityList() {
      api.getZsjyCompetition(this.data).then((res) => {
        console.log(res);
        this.activityList = res.data.rows;
        this.total = res.data.total;
      });
    },
    lookInfo(id) {
      // this.$router.push({ path: "/activity/info" });
      router.push({
                path: '/district/info',
                query: {
                    id: id
                }
            })
    },
  },
};
</script>
<style>
.el-pager li.is-active{
    color: #b52024 !important;
  }
</style>
<style lang="scss" scoped>
.ulbox{
  text-align: center;
  li{
    line-height: 40px;
    cursor: pointer;
  }
  .active{
    color: #b52024;
    font-weight: 700;
    // text-decoration: underline;
  }
}
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  &:hover {
    color: #b52024;
  }
}
.about {
  
  .el-breadcrumb {
    font-size: 16px;
  }
  .container {
    width: 1080px;
    margin: 0 auto;
    display: flex;
    min-height: calc(100vh - 290px);
    overflow: auto;
    .aside {
      width: 254px;
      background-color: #f5f5f5;
      > div {
        display: flex;
        align-items: center;
        padding-left: 0px;
        padding-top: 24px;
        font-size: 16px;
        > span {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .button {
      width: 200px;
      margin: 21px 27px;
      background-color: #d71011;
      font-size: 20px;
      height: 44px;
    }
    .content {
      width: 823px;
      background-color: #fff;
      > div:first-child {
        margin-top: 25px;
        margin-left: 34px;
        font-size: 20px;
        font-weight: 700;
        color: #d71011;
        border-bottom: 2px solid #d71011;
        width: 113px;
        padding-left: 19px;
        padding-right: 19px;
        padding-bottom: 14px;
      }
    }
    .conlist {
      padding-top: 32px;
      cursor: pointer;
    }
    .listhead {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .headfirst {
      display: flex;
      align-items: center;
    }
    .reddot {
      width: 5px;
      height: 5px;
      background: #d71011;
      margin-left: 34px;
      margin-right: 10px;
    }
    .listtitle {
      font-size: 20px;
      width: 700px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: #d71011;
      }
    }
    .listtime {
      font-size: 16px;
      color: #ccc;
      margin-right: 20px;
    }
    .listcon {
      font-size: 16px;
      margin-left: 34px;
      padding-top: 17px;
      color: #585858;
      border-bottom: 2px dashed #ccc;
      padding-bottom: 25px;
      margin-right: 20px;
      > span {
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
