<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      totalTime: 60 * 60,
    }
  },
  mounted() {
    console.log('mounted')
    var url = window.location.href;
    var qrCode = url.split('?qrCode=')[1];
    console.log(qrCode)
    localStorage.setItem('qrCode', null);
    console.log(localStorage.getItem('qrCode'))
    if(qrCode) {
      localStorage.setItem('qrCode', qrCode);
      console.log(localStorage.getItem('qrCode'))
      // let clock =  window.setInterval(() => {
      //   this.totalTime --;
      //   if(this. totalTime < 0){
      //     localStorage.setItem('qrCode', null);
      //     console.log(localStorage.getItem('qrCode'))
      //     window.clearInterval(clock);
      //   }
      // }, 1000);
    }
  }
}
window.onload = function() {
  document.addEventListener('touchstart', function(e) {
    console.log("1",e.touches.length)
    if (e.touches.length > 1) {
      e.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function(e) {
    console.log("2")
    e.preventDefault()
  })
}
</script>

<style>
/* PC 端样式 */
 @import "@/assets/lineAnimation.css";
body {
  background: #fbfbfb no-repeat fixed center bottom;
  background-size: cover;}
* {
  margin: 0;
  padding: 0;
}
#app {
  margin: 0 auto;
}
.main {
  width: 1080px;
  margin: 0 auto;
}
.flexBetween {
  display: flex;
  justify-content: space-between;
}
.text-center{
  text-align: center;
}
.content-right {
  position: relative;
  z-index: 0;
}


/* 手机端样式 */
@media (max-width: 767px) {
  #app {
    max-width: 100%;

  }
}
</style>
